.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  transition: left 0.3s ease;
  min-width: 100%;
}
.wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
}
.swiper {
  position: relative;
  margin: 0 auto;
  max-width: 1350px;
  width: 80%;
}
.breadcrumbs {
  position: sticky;
  top: -1px;
  z-index: 10;
  margin: 0 auto;
  background: white;
  padding: 10px 0;
  max-width: 1350px;
  width: 80%;
}
.main {
  position: relative;
  margin: 0 auto;
  max-width: 1350px;
  width: 80%;
  display: grid;
  grid-template-columns: 76% 24%;
}

/*.swiper {*/
/*  !*outline: 1px solid #d0b386;*!*/
/*  position: relative;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  width: 100%;*/
/*  min-height: calc(100vh - 65px);*/
/*  height: 100%;*/
/*}*/
.similar {
  margin-top: 50px;
  position: relative;
}
.similar h6{
  font-family: 'Glitten';
  font-size: 170%;
  margin-bottom: 20px;
  max-width: 1350px;
  width: 80%;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .container {
    padding-bottom: 50px;
  }
  .main {
    position: relative;
    display: block;
  }
  .swiper {
    position: relative;
    margin: 0 auto;
    width: 100%;
  }
  .ProductPrice-mobil-container button {
    position: relative;
    opacity: 0.8;
    font-weight: 200;
    padding: 0 10px;
    padding-left: 0 !important;
    color: black;
  }
  .ProductPrice-mobil-container {
    position: fixed;
    width: 100%;
    padding: 10px;
    padding-top: 20px;
    top: 0;
    left: 100%;
    height: 100vh;
    z-index: 100;
    background: white;
    transition: 0.3s ease;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .ProductPrice-mobil-container > div{
    /*margin: 0 !important;*/
    /*margin-top: 30px !important;*/
  }
  .bookingBlock {
    margin-top: 20px !important;
    position: relative;
    display: grid;
    grid-template-columns: 60% 35%;
    padding: 15px;
    grid-gap: 5%;
    border: 1px solid #ddd;
  }
  .bookingText h2{
    position: relative;
    font-size: 90% !important;
    line-height: normal !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .bookingText span{
    position: relative;
    color: #5C5C5C !important;
    font-size: 70%;
  }
  .bookingImage {
    position: relative;
    width: 100%;
    height: 70px;
    margin: 0 !important;
    overflow: hidden;
  }
  .bookingImage img{
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .similar {
    margin-top: 30px;
    position: relative;
  }
  .similar h6{
    font-family: 'Glitten';
    font-size: 130%;
    margin-bottom: 10px;
    width: 90%;
    max-width: 1552px;
    margin: 0 auto;
  }
  .breadcrumbs {
    width: 100%;
  }
  .main {
    width: 90%;
  }
  .similar h6{
    width: 90%;
  }
  .breadcrumbs {
    padding: 20px 5%;
  }
}
