.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 5px;
  transition: 0.3s;
  cursor: pointer !important;
}
.bottom-long {
  transition: 0.3s;
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  border-radius: 50%;
  background: #0095DB;
}
.container:hover .bottom-long {
  opacity: 0.4 !important;
  width: 130% !important;
}
.container:hover {
  opacity: 0.7;
}
.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.wrapper img {
  position: relative;
  height: 18px !important;
  width: 18px !important;
}
.title {
  font-family: 'Raleway', sans-serif !important;
  opacity: 0.5;
  font-size: 79%;
  font-weight: 600;
  color: black !important;
}
.data-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.data-show-text {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 90%;
  color: black !important;
  white-space: nowrap !important;
  font-family: Raleway, sans-serif;
}
@media (max-width: 767px) {
  .container {
    align-items: center;
    padding: 15px;
  }
  .container:hover .bottom-long {
    opacity: 1 !important;
    width: 70% !important;
  }
  .container:hover {
    opacity: 1;
  }
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 5px;
    transition: 0.3s;
    cursor: pointer !important;
  }
  .data-show-text {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 90%;
    color: black !important;
    white-space: nowrap !important;
    font-family: Raleway, sans-serif !important;
  }
  .titleActive {
    transition: 0.3s !important;
    color: #0095DB !important;
  }
  .bottom-long {
    transition: 0.3s;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    border-radius: 50%;
    background: #0095DB;
  }
}
