/* Стили для окна подсказок */
.pac-container {
  position: relative;
  z-index: 1000;
  background: white;
  transition: 0.3s;
  box-shadow: 0 0 0 0 #ffffff;
  border: 0.5px solid #70707040;
  display: flex;
  flex-direction: column;
  margin-left: -7px;
}

/* Стили для элементов списка подсказок */
.pac-item {
  padding: 2.5px 10px;
  cursor: pointer;
  transition: 0.3s;
  border: none;
  font-style: italic;
}
.pac-item-selected {
  background-color: #e5be8d; /* Измените цвет фона для выделенного элемента */
  transition: 0.3s;
  color: white;
}
.pac-item:hover {
  background: #0095DB;
  color: white;
}
.pac-item:hover .pac-item-query {
  color: white !important;
}
.pac-logo:after {
  display: none !important;
}
/* Стили для выделенного элемента списка подсказок */
.pac-item-selected .pac-item-query {
  color: white !important;
}

/* Стили для текста в элементе списка подсказок */
.pac-item-query {
  transition: 0.3s;
  font-weight: bold;
  font-style: normal;
}

/* Стили для адреса в элементе списка подсказок */
.pac-item-sub {
  color: #777; /* Цвет текста для адреса */
}

.pac-icon {
  display: none !important;
}

