.modal-title {
  font-family: "Glitten";
  font-size: 40px;
  color: #4e4439;
  margin-bottom: 0;
  margin-left: 20px;
  width: 100%;
  display: table;
  text-align: center;
  padding-top: 20px;
}