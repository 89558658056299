.container {
  position: relative;
  width: 100%;
  height: 630px;
  padding: 50px 0;
  margin: 0 auto;
  max-width: calc(1552px + 10%);
  background: none !important;
}
.wrapper {
  padding: 70px 0 50px 0;
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  margin: 0 auto;
  max-width: 1350px;
  width: 1350px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.background-svg {
  position: absolute;
}
.left-block {
  position: relative;
  width: 243px;
  height: 364px;
  margin-left: 25%;
  margin-bottom: 0;
  margin-top: auto;
}
.left-img {
  transition: 1s ease-in-out;
  transform: rotateZ(-20deg) translateX(-100px);
  transform-origin: right center;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.left-img-start-in-initial {
  transform: rotateZ(0deg) translateX(0px);
  opacity: 1;
}
.center-block {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.center-block h2 {
  position: relative;
  font-size: 350%;
  margin-bottom: 7px !important;
  padding: 0 !important;
  font-family: Glitten, sans-serif;
}
.center-block h3 {
  font-family: Glitten, sans-serif;
  position: relative;
  font-size: 250%;
}
.right-block {
  position: relative;
  width: 293px;
  height: 400px;
  margin-left: auto;
  margin-right: 25%;
}
.right-img {
  width: 100%;
  height: 100%;
  transition: 1s ease-in-out;
  transform: rotateZ(20deg) translateX(300px);
  transform-origin: left center;
  opacity: 0;
}
.right-img-start-in-initial {
  transform: rotateZ(0deg) translateX(0px);
  opacity: 1;
}
.buttons-block {
  position: relative;
  display: flex;
  grid-gap: 20px;
}
.button-item {
  position: relative;
  width: 150px;
  height: 45px;
  transition: opacity 0.3s, 0.7s ease;
  opacity: 0;
  transform-origin: center top;
  transform: scale(0.5);
}
.buttons-block-start-in-initial {
  transform: scale(1);
  opacity: 1;
}
.buttons-block button:hover {
  opacity: 0.7;
}
.buttons-block button img{
  position: relative;
  width: 100%;
  height: 100%;
}
@media (max-width: 1280px) {
  .container {
    position: relative;
    width: 100%;
    height: calc(530px);
    padding: 50px 0;
  }
  .right-block {
    width: calc(293px * 0.7);
    height: calc(400px * 0.7);
  }
  .left-block {
    width: calc(243px * 0.7);
    height: calc(364px * 0.7);
  }
  .wrapper {
    width: 100%;
  }
}
.center-block p{
  white-space: nowrap;
}
@media (max-width: 767px) {
  .wrapper {
    padding: 0;
    grid-template-columns: 1fr;
    width: 100%;
  }
  .left-block {
    display: none;
  }
  .right-block {
    display: none;
  }
  .center-block {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .center-block h2 {
    position: relative;
    font-size: 250%;
    margin: 0 !important;
  }
  .center-block h3 {
    position: relative;
    font-size: 150%;
  }
  .center-block p{
    display: block;
    position: relative;
    margin: 0 !important;
    font-size: 70%;
    white-space: normal;
  }
  .buttons-block {
    position: relative;
    display: flex;
    grid-gap: 20px;
    margin-top: 30px;
  }
  .button-item {
    width: 150px;
    height: 45px;
  }
  .container {
    position: relative;
    width: 100%;
    padding: 0 !important;
    margin: 30px 0;
    height: 200px;
  }
}
