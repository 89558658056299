.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.bottom-part-wrapper {
  position: relative;
  display: flex;
  padding: 20px 0;
  grid-gap: 40px;
}
.top-part-wrapper {
  position: relative;
  display: flex;
  padding: 40px 0;
  justify-content: space-between;
}
.center-blocks {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: start;
  align-content: start;
  margin-right: auto;
  margin-left: 0px;
  flex-direction: column;
  grid-gap: 0px;
}
.center-blocks a {
  transition: 0.3s;
  text-decoration: none;
  color: #90999e;
  transform: translateY(-20px);
}
.center-blocks p {
  font-family: Cambay, sans-serif;
}
.center-blocks a:hover {
  text-decoration: revert;
  color: white;
}
.center-blocks h5 {
  font-family: Cambay, sans-serif;
  position: relative;
  color: white;
  margin-bottom: 25px;
  font-weight: 100;
}
.network-block {
  position: relative;
  display: flex;
  flex-direction: row;
  grid-gap: 25px;
}

.network-block a {
  position: relative;
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50px;
}
.network-block a img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 35%;
}
.center-block-items p a {
  font-family: Cambay, sans-serif !important;
}

@media (max-width: 900px) {
  .center-blocks {
    text-align: start;
  }
  .center-blocks h5 {
    position: relative;
    color: white;
    margin-bottom: 5px;
    font-weight: 600;
  }
  .center-blocks a {
    transform: translateY(0px);
    margin-bottom: 0 !important;
    font-size: 24px !important;
  }
  .center-blocks p {
    font-size: 13.5px !important;
  }
  .bottom-part-wrapper {
    position: relative;
    display: flex;
    padding: 20px 0;
    grid-gap: 10px;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .bottom-part-wrapper p{
    font-size: 12px !important;
    color: #90999e;
  }
  .bottom-part-wrapper a{
    font-size: 12px !important;
    color: #90999e;
  }
  .container p{
    margin: 0 !important;
  }
  .top-part-wrapper {
    position: relative;
    display: flex;
    padding: 20px 0;
    grid-gap: 30px;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    text-align: center;
  }
  .network-block a {
    position: relative;
    width: 35px;
    height: 35px;
    background: #0095DB;
    border-radius: 50px;
  }
  .network-block a img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 40%;
  }
  .center-blocks-wrapper-mobil {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .center-blocks-wrapper-mobil div{
    margin: 0 !important;
  }
  .center-blocks-wrapper-mobil p{
    font-size: 12px !important;
  }
  .center-blocks-wrapper-mobil a{
    font-size: 12px !important;
  }
  .center-blocks-wrapper-mobil h5 {
    cursor: pointer;
    font-weight: 100;
    font-size: 14px !important;
  }
  .center-block-items {
    position: relative;
    height: 0;
    overflow: hidden;
  }
}