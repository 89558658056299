.container {
  position: relative;
  display: flex;
  width: max-content;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}
.fon {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(3px);
}
@media (max-width: 767px) {
  .container {
    display: none;
  }
}