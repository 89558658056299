.container {
  position: relative;
  margin-bottom: 0;
  margin-top: auto;
}
.pagination {
  position: relative;
  margin: 50px auto;
  display: flex;
  width: min-content;
  list-style-type: none;
  grid-gap: 10px;
  padding: 0;
}
.page-item {
  font-size: 90%;
  border-radius: 2px;
  padding: 0px 5px;
  transition: 0.3s;
}
.page-item-active {
  background: #0095DB;
}
.page-link {
  padding: 3px;
  text-decoration: none;
  transition: 0.3s;
  color: black;
}
.page-link:hover {
  color: black;
}
.page-item-active .page-link {
  color: white !important;
}
.next {
  position: relative;
  margin-left: 30px;
  cursor: pointer;
  transition: 0.3s;
}
.prev {
  position: relative;
  cursor: pointer;
  margin-right: 30px;
  transition: 0.3s;
}
.next:hover {
  opacity: 0.7;
}
.prev:hover {
  opacity: 0.7;
}
