/*--global-css--*/
body {
  font-family: "Campton-medium" !important;
  font-weight: 400;
  font-style: normal;
  background: #fff;
  /* background-image: url(assets/images/icbackground.png); */
  color: #21323c;
  overflow: hidden;
  overflow-y: scroll;
}
button {
  outline: none ;
  border: none ;
  background: none;
}
.main-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
h2 {
  font-size: 32px;
  font-weight: 500;
  color: #1a1a1a;
  line-height: 41px;
  position: relative;
}
* {
  outline: none;
}

.common-btn {
  background: #0095DB;
  border: 0;
  width: auto;
  margin-right: auto;
  padding: 12px 72px;
  border-radius: 0px;
  font-size: 21px;
  text-decoration: none;
  outline: none;
  /* font-family: 'Circular Std'; */
  color: #fff;
  position: relative;
  font-size: 16px;
  font-weight: 500;
}
.pagination ul {
  margin: 0;
  padding: 0;
  display: flex;
  margin-bottom: 40px;
}
.pagination ul li a.active {
  color: #000;
}
.filter span {
  margin-right: 11px;
}
.modal-dialog {
  max-width: 50% !important;
}
.pagination ul li {
  list-style: none;
  position: relative;
  margin-right: 22px;
}
.list-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}
.filter select {
  border: 0;
  appearance: none;
  outline: none;
  font-size: 16px;
  color: #000000;
}
.pagination ul li::before {
  content: "/";
  position: absolute;
  left: -15px;
  top: 3px;
}
.pagination ul li:first-child::before {
  display: none;
}
.pagination ul li a {
  color: #454545;
  font-size: 18px;
  text-decoration: none;
}
.common-btn::after {
  content: "";
  position: absolute;
  background: #000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0px;
  transform: scale(0);
  transition: 0.5s;
}
div#exampleModal {
  background: #000000a8;
}
.common-btn span {
  position: relative;
  z-index: 1;
}
.common-btn:hover {
  color: #fff;
  transition: 0.5s;
}
.common-btn:hover::after {
  transform: scale(1);
  transition: 0.5s;
}
.banner-page h1 {
  font-size: 105px;
  font-weight: 400;
  line-height: 105px;
  /* font-family: "Glitten"; */
  color: #fff;
}
.container {
  max-width: 1552px !important;
}
p {
  font-size: 16px;
  line-height: 21px;
  color: #00000091;
  /* font-family: 'Circular Std'; */
}
.banner-page p {
  color: #fff;
}

nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  background: transparent !important;
  padding: 0;
}

.back-bn {
  margin-bottom: 19px;
}
.sale-btn {
  position: relative;
}
.sale-btn span {
  background: #000000db;
  color: #fff !important;
  border: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  font-size: 21px;
  padding: 15px 0;
  font-family: "Campton-medium";
  transform: scale(0);
  transition: 0.5s;
  text-align: center;
  text-decoration: none;
}
.sale-btn:hover span {
  transform: scale(1);
  transition: 0.5s;
  text-align: center;
  text-decoration: none;
}
.property a,
.mbile-slider a,
.pro-list a,
.inn-th-slide a {
  text-decoration: none;
}
.sale-btn:hover a:hover {
  color: #fff;
}
.seacrhtime .srchtab span input {
  border: 0;
  font-size: 17px;
}
.footer-left a:hover {
  color: #fff;
}
.register-property span {
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid;
  position: relative;
}
.appartments {
  position: absolute;
  top: 18px;
  right: 10px;
  display: flex;
}
.register-property span a {
  position: absolute;
  left: 0;
  width: 100%;
  height: 27px;
  font-size: 0;
  z-index: 9;
}
.product-detail-page .pagination ul li:nth-child(2)::before {
  opacity: 0;
}
.modal-images {
  display: flex;
  flex-wrap: wrap;
}
.pay-image img {
  width: 100%;
}
.tours-cards.similar-pro .tours-cards-inner {
  margin-bottom: 30px;
}
.trip-outer button {
  background: #0095DB;
  border: 0;
  width: auto;
  margin-right: auto;
  padding: 8px 22px 8px;
  border-radius: 0px;
  font-size: 21px;
  text-decoration: none;
  outline: none;
  /* font-family: 'Circular Std'; */
  color: #0095DB;
  position: relative;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
}
.outer-plus {
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
}
.modal-images div {
  width: 50%;
}
.modal-images div img {
  width: 100%;
  padding: 5px;
  height: 260px;
  object-fit: cover;
  border-radius: 10px;
}
.inner-bath-list .bad-name {
  border-bottom: 1px solid #ddd;
  padding-bottom: 6px;
  margin-bottom: 13px;
}
.inner-bath-list .bad-name:last-child {
  border: 0;
  margin-bottom: 0;
}
.inner-bath-list p.bath_tag.inside {
  background: #4800ff40;
}
.inner-bath-list p.bath_tag.ensuit {
  background: #ffb60040;
}
.inner-bath-list p.bath_tag.open-list {
  background: #bac3cc;
}
.inner-bath-list p.bath_tag {
  background: #bac3cc;
  position: static;
  top: 18px;
  right: 10px;
  font-size: 14px;
  padding: 4px 10px 1px;
  border-radius: 100px;
  background: #00ccff40;
  margin-left: 6px;
}
.my-gallary .modal-body {
  padding: 3px;
}
.modal-header button.btn-close {
  position: absolute;
  top: -10px;
  right: -2px;
  background-color: #ffff;
  opacity: 1;
  border: 0;
  width: 35px;
  height: 35px;
}
.modals ul {
  padding: 0;
  margin-bottom: 8px;
  display: flex;
}
.modals ul li:first-child {
  list-style: none;
}
.modals ul li {
  margin-right: 20px;
  padding-right: 10px;
  color: #a0a0a0;
}
.modals ul li b {
  padding-left: 6px;
}
.price-table .form-group input[type="radio"] {
  width: auto;
  height: auto;
  margin-right: 4px;
}
.seacrh-bn form {
  width: 100%;
  display: flex;
}
.tours-desc-left h4 {
  font-size: 40px;
  font-family: "Glitten";
}
.tours-desc-left h5 {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 46px;
}
.tours-desc-left h5 img {
  margin-right: 9px;
}
.tour-brd {
  border-top: 1px solid #0095DB;
  padding: 32px 0 12px;
  border-bottom: 1px solid #0095DB;
}
.tour-brd h6 {
  font-size: 21px;
}
.tour-brd p {
  font-size: 17px;
}
.tours-slide {
  padding: 0px 0 30px;
}
.tours-lising a:hover {
  color: #000;
}
.tours-slide span {
  font-size: 15px;
  text-decoration: none;
  color: #a4a4a4;
  border: 1px solid #e2e2e2;
  padding: 14px 13px 10px;
  text-align: center;
  border-radius: 5px;
  margin: 0 5px;
}
.tours-slide span.active {
  background: #0095DB;
  color: #fff;
  border-color: #0095DB;
}
.tours-desc-right {
  text-align: right;
}
.tours-slide .slick-slide {
  margin-right: 2px;
  margin-left: 2px;
}

.tours-cards h4 {
  font-size: 28px;
  margin-bottom: 11px;
}
.tours-cards-inner img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}
.tours-cards-inner h5 {
  font-size: 21px;
  margin: 12px 0;
}
.chat-fixed {
  position: fixed;
  bottom: 90px;
  right: 10px;
}
.open-chat {
  background: #0095DB;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 35px;
  color: #fff;
}
.modal-body.opt-form form .from-group {
  width: 100%;
}
.vendors-list ul {
  padding: 0;
  margin: 0;
}
.vendors-list ul li {
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: flex-end;
  margin: 11px 0;
  transition: 0.3s;
}
.vendors-list ul li img {
  max-width: 50px;
  max-height: 50px;
  min-height: 50px;
  border-radius: 1000px;
  object-fit: cover;
  box-shadow: 0 0 10px 1px #ddd;
  margin-right: 13px;
}
.vendors-list ul li:hover {
  background: #fff;
  box-shadow: 0 0 10px 1px #ddd;
  padding: 9px;
  border-radius: 6px;
  transition: 0.3s;
}
.chat-message {
  position: fixed;
  bottom: 18px;
  right: 100px;
  background: #fff;
  box-shadow: 0 0 10px 1px #dddddd82;
  border-radius: 10px;
  min-width: 410px;
}
.vendors-list ul li h5 {
  font-size: 14px;
  color: #000;
  margin: 0;
}
.header-chat img {
  max-width: 70px;
  min-height: 70px;
  border-radius: 70px;
  object-fit: cover;
  margin-right: 12px;
}
.header-chat {
  display: flex;
  align-items: center;
  background: #0095DB;
  color: #fff;
  padding: 10px;
  position: relative;
}
.vendors-list ul li h5 {
  font-size: 0;
  transition: 0.3s;
  margin: 0;
}
.header-chat label {
  position: absolute;
  right: 20px;
  font-size: 32px;
  top: 50%;
  transform: translateY(-50%);
}
.chat-body {
  position: relative;
  padding: 16px;
}
.innerr-chat-body ul {
  padding: 0;
  margin: 0;
}
span.edit-btn {
  cursor: pointer;
}
.innerr-chat-body ul li {
  list-style: none;

  margin: 10px 0;
}
.innerr-chat-body ul li.user-chat {
  background: #ffe6c7;
  padding: 10px;
  border-radius: 9px;
  max-width: 80%;
  font-size: 14px;
}
.innerr-chat-body ul li.vendor-chat {
  background: #ffd6d6;
  padding: 10px;
  border-radius: 9px;
  max-width: 80%;
  font-size: 14px;
  margin-left: auto;
}

.chat-page {
  position: relative;
  z-index: 99;
}
ul.sub-cat {
  padding: 0;
  margin: 0 0 20px;
  display: flex;
  text-align: center;
  /* border: 1px solid #ddd; */
}
ul.sub-cat li h5 {
  font-size: 18px;
  margin-bottom: 7px;
}
.log-btn span img {
  max-width: 43px;
  border-radius: 100px;
  max-height: 43px;
  min-height: 43px;
  object-fit: cover;
}
ul.sub-cat li h6 {
  margin: 0;
  font-size: 24px;
  color: #0095DB;
  font-weight: 800;
}
ul.sub-cat li {
  border: 1px solid #ddd;
  padding: 11px;
  list-style: none;
  margin-right: 14px;
  border-radius: 8px;
}
.log-btn ul {
  margin: 0;
  position: absolute;
  top: 50px;
  right: 0;
  min-width: 200px;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 0 10px 1px #ddddddc9;
  padding: 14px;
  left: 50%;
  transform: translateX(-50%);
}
.log-btn {
  position: relative;
  font-size: 85% !important;
  font-weight: 500 !important;
}
@media (max-width: 767px) {
  .log-btn {
    font-size: 70%;
  }
}
.log-btn ul li {
  list-style: none;
  margin: 8px 0;
}
p.error-msg {
  text-align: center;
  color: red;
  font-weight: 700;
  font-size: 24px;
  margin: 50px 0;
}
ul.sub-cat li.active {
  background: #0095DB;
  color: #fff;
}
ul.sub-cat li.active h6 {
  color: #fff;
}
.log-btn ul li a {
  color: #000;
  text-decoration: none;
  background: transparent;
  box-shadow: none;
  width: 100%;
  display: table;
  padding: 0;
}
.sent-message {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: table;
  width: 100%;
  max-width: 94%;
}
.sent-message .from-group input {
  width: 100%;
  height: 43px;
  border: 1px solid #ddd;
  border-radius: 40px;
  padding: 0 12px;
  font-size: 14px;
}
.sent-message .from-group {
  display: flex;
  width: 100%;
  position: relative;
}
.sent-message .from-group button {
  position: absolute;
  right: 4px;
  top: 4px;
  height: 35px;
  width: 35px;
  background: #0095DB;
  border: 0;
  border-radius: 100px;
  color: #fff;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-list ul li.active {
  background: #0095DB;
  color: #fff;
}
.list-filter button.filter-btn:hover {
  color: #fff;
  background: #0095DB;
}
.innerr-chat-body {
  padding-bottom: 50px;
  max-height: 360px;
  overflow-y: auto;
  min-height: 100px;
}
.user-icon i {
  color: #0095DB;
}
.vendors-list ul li:hover h5 {
  font-size: 14px;
  transition: 0.3s;
}
.sent-message button {
  position: absolute;
  right: 18px;
  top: 12px;
  background: transparent;
  border: 0;
  color: #0095DB;
  padding: 0;
}
.vendors-list {
  position: fixed;
  bottom: 81px;
  right: 8px;
  background: transparent;
  padding: 10px;
  border-radius: 7px;
  box-shadow: none;
}
.modal-body.opt-form form .from-group input {
  width: 510px;
  font-size: 24px;
  border: 0;
  margin-left: 60px;
  letter-spacing: 106px;
  outline: none;
}
.modal-body.opt-form form {
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.price-table div {
  position: static;
}
div#daterangepicker {
  position: fixed;
  left: 50% !important;
  transform: translateX(-50%);
  width: auto;
  padding: 10px;
  border-radius: 10px;
}
section.terms-sec {
  padding: 40px 0;
}

.tours-lising {
  padding: 20px 0;
}
.product-detail-page p {
  font-size: 20px;
  line-height: 33px;
  color: #000;
  font-family: "Campton-light";
}
div#daterangepicker .fromDateTimeContainer {
  float: left;
}
.price-table {
  z-index: 9;
}
section.about-sec.notification-page {
  max-width: 660px;
  margin: 0 auto;
}
.notif-nav li a {
  color: #000;
  text-decoration: none;
}
.my-review-page {
  padding: 40px 0 10px;
}
span.sen-dot {
  width: 10px;
  height: 10px;
  background: #e89835;
  position: absolute;
  border-radius: 100px;
  right: -7px;
  bottom: 11px;
}
.product-detail-page h6 {
  color: #4e4439;
  font-size: 21px;
  margin-top: 20px;
}
.product-slider-main .more-up {
  opacity: relative;
  bottom: 77px;
}
div#daterangepicker .rangecontainer {
  display: none !important;
}
.product-slider-main .side-sider .slick-track {
  width: 100% !important;
}
.product-slider-main .side-sider .slick-track img {
  width: 100%;
  background-size: cover;
  border-radius: 0px !important;
}
.slider-desc h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 22px;
  color: #4e4439;
}

.slider-desc p {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}
.list-s-none {
  list-style: none;
}
.agent-modal .modal-dialog {
  max-width: 30% !important;
}
.form-group.add-image.new-add-gallery div span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100px;
  background: #0095DB;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 29px;
  color: #fff;
  pointer-events: none;
}
.form-group.add-image.new-add-gallery div input {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 30px;
  font-size: 0;
  z-index: 9;
  position: relative;
}
body .edit-profile-main form .form-group .main-img-priew img {
  position: absolute;
  z-index: 9;
  pointer-events: none;
}
.edit-profile-main form .form-group .main-img-priew button {
  position: absolute;
  right: 0;
  width: 30px;
  padding: 6px;
  height: 30px;
  border-radius: 100px;
}
.edit-profile-main form .form-group .main-img-priew img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
}
body .edit-profile-main form .form-group .main-img-priew {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
}
.form-group.add-image.new-add-gallery div {
  position: relative;
  width: 100px;
  height: 100px;
}
.filter-tab ul {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  width: 100%;
}
.filter-tab ul li {
  list-style: none;
  width: 20%;
  text-align: center;
  padding: 0 10px;
}
.filter-tab ul li span {
  background: #fff;
  color: #0095DB;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  padding: 12px 0 9px;
  border: 1px solid #0095DB;
  cursor: pointer;
}
.filter-tab ul li.active span {
  background: #0095DB;
  color: #fff;
}
.ft-tabs ul {
  padding: 0;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 18px;
  margin-bottom: 30px;
}
.ft-tabs ul li {
  width: 33.33%;
  text-align: center;
  list-style: none;
  position: relative;
  cursor: pointer;
}
.ft-tabs ul li::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 20px;
  background: #c3c3c3;
  right: 0;
  top: -1px;
}
.ft-tabs ul li:last-child::before {
  opacity: 0;
}
.ft-tabs ul li a.active {
  color: #0095DB;
  font-weight: 800;
}
.book-main-img img {
  width: 100%;
  height: 170px;
  border-radius: 0px;
  object-fit: cover;
}
.booking-detail.my-bboking-main {
  padding-top: 40px;
}
.booking-detail h5 {
  font-size: 21px;
  margin: 19px 0 10px;
  display: flex;
  justify-content: space-between;
  font-family: "Campton-medium" !important;
  text-decoration: underline;
}
.booking-det-right {
  max-width: 80%;
  margin-left: auto;
  border: 1px solid #ddd;
  padding: 25px 0;
}
.inner-date {
  padding: 0 25px;
}
.detail-property h4 {
  font-size: 21px;
  color: #4e4439;
  margin-top: 32px;
}
.detail-property ul.amnites {
  margin: 0;
}
.booking-det-left {
  display: flex;
  align-items: center;
}
.booking-detail {
  padding: 0 0 90px;
}
.booking-descroiptons p {
  margin-bottom: 5px;
}
.log-btn a {
  color: #000;
  text-decoration: none;
}
.edit-profile-main form {
  max-width: 500px;
  margin: 40px auto 40px;
  box-shadow: 0 0 10px 1px #ddd;
  padding: 30px;
  border-radius: 10px;
  border-top: 4px solid #0095DB;
}
.edit-profile-main form label {
  width: 100%;
  font-size: 16px;
  color: #868686;
}
.edit-profile-main form input {
  width: 100%;
  padding: 0 10px;
  font-size: 15px;
  border: 1px solid #ddd;
  color: #636363;
  height: 42px;
  border-radius: 5px;
}
.edit-profile-main form .form-group {
  margin-bottom: 16px;
}
.edit-profile-main form button {
  background: #0095DB;
  border: 0;
  color: #fff;
  height: 40px;
  padding: 2px 30px 0;
}
.tours-lising a {
  color: #000;
  text-decoration: none;
}
.back-bn div {
  color: #0095DB;
  text-decoration: none;
  cursor: pointer;
}

.back-bn div i {
  margin-right: 10px;
}
body .dropdown button {
  background: transparent !important;
  box-shadow: none;
  padding: 0;
}
.dropdown button img {
  max-width: 44px;
  border-radius: 100px;
}
.booking-descroiptons {
  width: 100%;
  padding-right: 20px;
  float: none;
}
.book-main-img {
  width: 36%;
  float: left;
  padding-left: 0;
  margin-right: 20px;
}
.payment-tab-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
  margin-bottom: 20px;
}
.booking-descroiptons ul {
  margin: 15px 0 10px;
  padding-left: 17px;
}
.booking-descroiptons ul li {
  margin-bottom: 5px;
}
.payment-tab-head h4 {
  font-size: 18px;
  color: #3b3b3b;
  text-transform: uppercase;
}
.payment-tab-head span {
  background: #fc62624d;
  padding: 5px 10px 2px;
  font-size: 15px;
  border-radius: 0px;
  position: relative;
}
.payment-tab-head span label {
  width: 10px;
  height: 10px;
  background: #fc6262;
  border-radius: 100px;
  margin-right: 6px;
}
.payment-details-table table {
  width: 100%;
}
.bookng-table-cart {
  border: 1px solid #ddd;
}
.payment-tab-head span.payemnt-done label {
  background: #1daf0c;
}
.payment-tab-head span.payemnt-done {
  background: #00800040;
}
.price-table .outer-plus {
  border: 0;
}
.detail-property h4 span {
  font-size: 14px;
}
.bookng-table-cart tr th,
.bookng-table-cart tr td {
  font-size: 18px;
  font-family: "Campton-normal";
  color: #5d5d5d;
  padding: 13px 28px;
  border-bottom: 1px solid #ddd;
}

tr.discount-disc td {
  color: #0095DB;
  font-family: "Campton-semibold";
}
.bookng-table-cart tr td {
  color: #000;
  border: 0;
}
.vat-group {
  background: #d9d9d9;
  padding: 12px 0;
}
tr.discount-row td:nth-child(3) {
  color: #a0a0a0;
}
.vat-group ul {
  padding: 0;
  margin: 0;
}
.detail-property h6 {
  color: #4e4439;
  font-size: 16px;
  font-family: "Campton-normal";
}
.inner-date h4 span {
  font-size: 16px;
  text-transform: capitalize;
  color: #000000;
}
.booking-det-right h6 span {
  color: #000;
  font-family: "Campton-semibold";
}
.booking-det-right h6 {
  padding: 0 31px;
  color: #808080;
  font-size: 20px;
  font-family: "Campton-normal";
  margin: 0;
}
.row.border-btnv {
  margin: 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
  margin-bottom: 21px;
}
.inner-date h4 span {
  font-size: 16px;
}
.inner-date h4 {
  font-size: 19px;
}
.inner-date span {
  font-size: 16px;
  text-transform: uppercase;
  color: #a0a0a0;
  font-family: "Campton-normal";
}
.price-table .outer-plus button {
  background: #0095DB;
  border: 0;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  color: #fff;
  padding: 4px 0 0;
}
.price-table .outer-plus span {
  padding: 0 8px;
}

.files-dropzone-list {
  font-size: 13px;
  color: #b1b1b1;
  border: 1px dashed #b1b1b1;
  text-align: center;
  padding: 15px 0 13px;
  border-radius: 8px;
  margin-top: 7px;
  margin-bottom: 13px;
}
body button.remove-files {
  width: auto;
  color: red;
  background: transparent;
  margin-left: auto;
  float: right;
  padding: 0;
}
.bookng-table-cart tr td span {
  margin-right: 15px;
  position: relative;
}
.bookng-table-cart tr td span::before {
  content: "";
  position: absolute;
  right: -10px;
  top: 6px;
  width: 5px;
  height: 5px;
  background: #272727;
  border-radius: 10px;
}
.bookng-table-cart tr td span:last-child::before {
  opacity: 0;
}
.files-list ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.files-list ul li {
  width: 31.3%;
  margin-right: 9px;
  list-style: none;
  font-size: 12px;
}
.add-.review button {
  background: #0095DB;
  color: #fff;
  border: 0;
  padding: 9px 20px 6px;
  border-radius: 4px;
}
.modal-dialog.review-modal h5#exampleModalLabel {
  text-align: center;
  width: 100%;
  display: table;
  margin: 0;
}
.files-list ul li img {
  width: 100%;
  border: 1px dashed #ddd;
  padding: 4px;
  height: 90px;
  border-radius: 5px;
  object-fit: cover;
}
.files-list {
  width: 100%;
  float: left;
  margin-top: 8px;
  margin-bottom: 20px;
}
.modal-new .modal-body {
  padding: 22px;
}
.page-modal button span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.page-modal form .from-group input[type="submit"] {
  width: 100%;
  border: 0;
  background: #0095DB;
  color: #fff;
  padding: 14px 0 12px;
}

.page-modal button {
  font-size: 14px;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  background: #0095DB;
  border: 0;
  color: #fff;
  padding: 17px 0;
  width: 100%;
  height: auto;
  position: relative;
}
.page-modal button.upoad-btn {
  width: auto;
  margin: 0 auto;
  display: table;
  padding: 7px 23px;
}

.rating {
  margin-top: 00px;
  border: none;
  float: left;
}

.rating > label {
  color: #90a0a3;
  float: right;
}
.modal-dialog.review-modal .rating label {
  width: auto;
}
ul.review-list li span i {
  color: #0095DB;
  font-size: 21px;
  margin-right: 7px;
}
ul.review-list span label {
  color: #969696;
  font-size: 14px;
  font-style: italic;
  margin-left: 6px;
}
ul.review-list li {
  list-style: none;
}
ul.review-list span {
  font-size: 20px;
}
.modal-new {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background: #00000080;
}
.modal-new .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.empty-star i {
  color: #c9c9c9 !important;
}
.empty-star {
  margin-top: -30px;
}
.filed-star {
  position: relative;
}
ul.review-list li .box-sh-list {
  list-style: none;
  box-shadow: 0 0 10px 1px #ddd;
  padding: 16px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.edit-btns span {
  margin-left: 13px;
  cursor: pointer;
}
ul.review-list .edit-btns span {
  font-size: 15px;
  color: #7f7f7f;
}
ul.review-list .edit-btns span i {
  font-size: 15px;
  background: #0095DB;
  padding: 10px;
  border-radius: 100px;
  color: #fff;
  margin: 0;
}
.edit-btns {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
ul.review-list li p {
  margin-top: 11px;
}
.modal-new button[type="submit"] {
  margin: 0 auto;
  display: table;
  background: #0095DB;
  border: 0;
  color: #fff;
  padding: 12px 50px 10px;
  border-radius: 5px;
}
.product-detail-page .date-create h6 {
  font-size: 16px;
  margin: 0;
  color: #9f9f9f;
}
.reviewlist ul li p {
  color: #6d6d6d;
  font-size: 16px;
  margin-bottom: 0;
  font-family: 'Campton-normal';
  margin: 12px 0 0;
  line-height: 25px;
  padding-right: 60px;
}
.pro-list .row {
  margin: 0;
}
.review-image {
  display: flex;
  align-items: center;
}
.review-image img {
  max-width: 60px;
  max-height: 60px;
  min-width: 60px;
  min-height: 60px;
  border-radius: 100px;
  margin-right: 11px;
}
.review-lit-iner h5 {
  margin: 0;
  font-size: 18px;
  color: #7e7e7e;
  font-family: 'Campton-light';
}
.review-lit-iner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 82%;
  margin: 5px 0;
}
.footer-menu li {
  color: #999fa3;
}
.reviewlist ul li h5 {
  color: #000;
  font-size: 19px;
  margin-bottom: 3px;
}
.witch-user {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.swich-btn {
  margin: 0 20px;
}
.review-lit-iner h6 {
  color: #0095DB;
  margin-left: 11px;
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 0;
}
.filed-star span.star i {
  line-height: 43px;
  color: #0095DB;
}
.my-review-page ul .date-create h6 {
  margin: 0;
  font-size: 13px;
}
.rating-cat {
  background: #efefef;
  display: table;
  width: 100%;
  padding: 13px 15px;
  margin: 80px 0 20px;
}
.rating-cat .from-group {
  width: 50%;
  float: left;
}
.ranger-review span label {
  background: #0095DB;
  border-radius: 10px;
}
.show-more {
  width: 100%;
  display: table;
}
.show-more span {
  border: 1px solid #0095DB;
  color: #0095DB;
  padding: 17px 30px 14px;
}
.ranger-review span {
  width: 207px;
  background: #fbd29f;
  height: 5px;
  border-radius: 7px;
  display: flex;
}
.ranger-review {
  display: flex;
  align-items: center;
}
.reviewlist ul {
  padding: 0;
  display: table;
  margin: 58px 0 0;
  width: 100%;
}
.reviewlist ul li {
  list-style: none;
  border-radius: 0;
  margin-bottom: 18px;
  width: 48%;
  float: left;
  margin: 0 9px 47px;
  padding-right: 50px;
}
.product-detail-page.main-pro-detial .price-table label span {
  opacity: 0;
}
ul.review-list {
  padding: 0;
  padding-top: 20px;
}
body .reviewlist h4 {
  display: inherit;
  font-size: 25px;
  margin-top: 30px;
  margin-bottom: 50px;
}
.from-group.textraea-field input {
  border: 1px solid #ddd;
  width: 100%;
  height: auto;
  padding: 7px;
  border-radius: 4px;
}
.tour ol.list-group li.list-item {
  font-size: 20px;
}
.tour ol.list-group {
  font-size: 0;
}
p.no-rest {
  text-align: center;
  font-size: 22px;
  margin-top: 18px;
}
.check-avail h4 {
  font-size: 15px;
  color: #727272;
}
span.vendor-name i {
  margin-right: 15px;
  font-size: 24px;
}
span.vendor-name {
  font-size: 18px;
  color: #4e4439;
}
.from-group.textraea-field {
  margin-bottom: 21px;
}
.rating > label:before {
  margin: 5px;
  font-size: 2em;
  font-family: FontAwesome;
  content: "\f005";
  display: inline-block;
}

.rating > input {
  display: none;
}

.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #f79426;
}

.rating > input:checked + label:hover,
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label,
.rating > input:checked ~ label:hover ~ label {
  color: #fece31;
}
.modal-dialog.review-modal {
  max-width: 30% !important;
  top: 30px;
}
.modal-dialog.review-modal label {
  width: 100%;
}
body.home-page .headerpage {
  background: transparent;
}
.sl-bubble5 {
  position: fixed;
  background: #ffffffba;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
}

.footer-bar {
  position: fixed;
  bottom: 0;
  background: #fff;
  left: 0;
  right: 0;
  padding: 19px 0 13px;
  display: none;
  z-index: 99;
  box-shadow: 0 0 10px 1px #dddddd6e;
}
.product-detail-page.main-pro-detial tfoot {
  display: none;
}
.footer-bar ul {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.footer-bar ul li {
  list-style: none;
  width: 25%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

}
.chat-fixed .open-chat img {
  max-width: 41px;
}
.header-chat label img {
  max-width: 21px;
  height: auto;
  object-fit: contain;
}
.filter-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-main label {
  width: 100%;
}
.filter-tab .form-group {
  width: 47%;
}
.filter-main input {
  border: 1px solid #0095DB;
  padding: 0 10px;
  height: 38px;
  width: 100%;
}
.footer-bar ul li a {
  opacity: 0.5;
}
.footer-bar ul li a {
  color: #000;
}
.footer-bar ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.footer-bar ul li a.active {
  opacity: 1;
}
.footer-bar ul li img {
  margin-right: 9px;
  position: relative;
  top: -2px;
  margin: 0 auto 2px;
}
@media (max-width: 767px) {
  body {
    padding-bottom: 0px !important;
  }
}