.container {
  position: relative;
  padding-right: 3.5%;
}
.container p{
  font-family: Campton-light;
}
.time {
  position: relative;
  display: flex;
  margin-top: 10px;
  align-items: center;
  grid-gap: 10px;
}
.time img{
  position: relative;
  height: 15px;
  width: 15px;
}
.time p{
  margin: 0;
  font-family: Campton-medium!important;
  color: #4e4439;
  font-size: 90%;
  font-weight: 300;
}
.name {
  margin-top: 20px;
  font-family: Glitten;
  font-size: 150%;
  color: #4E4439;
}
.location {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.location img{
  position: relative;
  height: 20px;
}
.location h4 {
  ont-family: Campton-medium!important;
  color: #4E4439;
  margin: 0;
  font-size: 110%;
}
.property-info-block {
  position: relative;
  margin-top: 25px;
  display: flex;
  grid-gap: 10px;
}
.property-info-block p{
  margin: 0;
  white-space: nowrap;
  color: #4E4439;
}
.description-block {
  position: relative;
}
.description-block h6{
  position: relative;
  font-weight: 500;
}
.description-block pre {
  white-space: pre-line;
  color: black;
  font-weight: 500;
  position: relative;
  font-family: Campton-light;
}
.ammenities-wrapper {
  position: relative;
}
.ammenities {
  position: relative;
  background: #F7F7F7;
  display: grid;
  width: max-content;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 20px;
  flex-wrap: wrap;
  grid-gap: 20px 40px;
}
.ammenitiesCar {
  display: flex;
}
.ammenities-item {
  position: relative;
  display: flex;
  white-space: nowrap;
  grid-gap: 10px;
  justify-content: start;
  align-items: center;
}
.ammenities-item img{
  position: relative;
  height: 20px;
  opacity: 0.7;
}
.ammenities-item p{
  font-family: Campton-light;
  color: #333333;
  font-weight: 700;
  margin: 0;
  position: relative;
}
.map {
  position: relative;
  margin-top: 20px;
}
.mapWrapper {
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 10px;
}

.description-block-read-more {
  position: relative;
  padding: 0 !important;
  color: #0095DB;
  font-weight: 600;
}

.bottomLong {
  margin-top: 40px;
}
.subCategories-buttons-block {
  position: relative;
  display: flex;
  grid-gap: 20px;
  margin-top: 20px;
}
.subCategories-button {
  background: #0095DB;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7px 9px;
  transition: 0.3s;
  border: 1px solid #0095DB;
}
.subCategories-button:hover {
  opacity: 0.5;
}
.subCategories-button-off {
  background: none;
  border: 1px solid #0095DB;
}
.subCategories-button h5{
  font-family: Campton-medium!important;
  font-size: 90%;
  color: white;
  margin: 0;
}
.subCategories-button-off h5{
  color: black;
}
.subCategories-button h6{
  font-family: Campton-medium!important;
  font-size: 90%;
  color: white;
  margin: 0;
  margin-top: 5px;
}
.subCategories-button-off h6{
  color: #0095DB;
}
.subCategories-button h6 span{
  color: white;
  margin: 0;
  margin-left: 5px;
  font-size: 120%;
}
.subCategories-button-off h6 span{
  color: #0095DB;
}
.what-expect-container {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  margin-left: 30px;
}
.what-expect-item {
  display: flex;
  flex-direction: column;
}
.what-expect-item p{
  position: relative;
  margin: 0;
  color: black;
}
.what-expect-item p:before{
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background: black;
  border-radius: 50%;
  top: 4px;
  left: -29px;
}
.what-expect-item span{
  opacity: 0.7;
}
.vertical-long {
  position: absolute;
  top: 7.5px;
  left: -23.5px;
  width: 1.5px;
  height: 100%;
  background: black;
}
.what-expect-item-end {
  margin-left: 30px;
}
@media (max-width: 767px) {
  .bottomLong {
    margin-top: 20px;
  }
  .mapWrapper {
    position: relative;
    width: 100%;
    height: 200px;
    margin-bottom: 5px;
  }
  .name {
    margin-top: 20px;
    font-family: Glitten;
    font-size: 140%;
    color: #4E4439;
  }
  .subCategories-button:hover {
    opacity: 1;
  }
  .subCategories-buttons-block {
    position: relative;
    display: flex;
    grid-gap: 20px;
    margin-top: 10px;
  }
  .location {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    font-size: 110%;
  }
  .location img{
    position: relative;
    height: 15px;
  }
  .location h4 {
    font-family: Campton-medium!important;
    color: #4E4439;
    margin: 0;
    font-size: 80%;
  }
  .property-info-block {
    position: relative;
    margin-top: 15px;
    display: flex;
    grid-gap: 5px;
  }
  .property-info-block p{
    margin: 0;
    white-space: nowrap;
    color: #4E4439;
    font-size: 80%;
  }
  .ammenities {
    position: relative;
    background: transparent;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0;
    padding-top: 5px;
    flex-wrap: wrap;
    grid-gap: 10px 20px;
  }
  .ammenities-item img{
    position: relative;
    height: 20px;
    opacity: 0.7;
  }
  .ammenities-item p{
    font-family: Campton-light;
    color: #333333;
    font-size: 80%;
    font-weight: 700;
    margin: 0;
    position: relative;
  }
  .description-block {
    position: relative;
    margin-top: 20px;
  }
  .container {
    position: relative;
    padding-right: 0%;
  }
  .description-block p {
    color: black;
    font-size: 85%;
    font-weight: 500;
    position: relative;
    font-family: Campton-light;
  }
  .time {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    grid-gap: 10px;
  }
  .time p{
    margin: 0;
    font-family: Campton-medium!important;
    color: #4e4439;
    font-size: 80%;
    font-weight: 300;
    margin-top: 3px;
  }
}