.trip-enquiry {
    border: 0.914062px solid rgba(0, 0, 0, 0.3);
}

.pick-up {
    padding: 32px;
}

.packages {
    padding: 32px;
    border-top: 0.911079px solid #B3B3B3;
    border-bottom:  0.911079px solid #B3B3B3;
}

.package-booking {
    padding: 32px;
}

.pick-up ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    color: #21323C;
  
    font-weight: bold;
}

.pick-up h5 {
    margin-bottom: 25px;
}


.packages .pack {
    border: 0.5px solid #B3B3B3;
    text-align: center;
 
    font-weight: bold;
    margin-bottom: 10px;
}

.packages .package-price {
    border-top: 0.5px solid #B3B3B3;
    padding: 16px;
}

.package-price .view-all-btn a {
    display: block;
}

.packages .pack > h6 {
    padding: 16px 16px 0;
    color: #000000;
    font-weight: bold;
    margin-bottom: 0;
}

.packages .pack > p {
    margin-bottom: 0;
    padding: 0 16px 16px;
    font-size: 14px;
}

.package-price h6 {
    color: #05A068;
    margin-bottom: 0;
    font-weight: bold;
}

.package-price > a {
    padding: 16px 0px;
    display: block;
    color: #21323C;
    text-decoration: underline;
}
.desc img {
    width: 100%;
}
.packages > h6 {
    color: #000000;
   
    font-weight: bold;
    margin-bottom: 15px;
}

.calendar {
    font-size: 21px;
}

.pick-up ul b {
    color: #000000;
}

.singular label:first-child {
    margin-right: 40px;
}

.singular {
    margin: 30px 0 30px;
}

.singular label {
    font-size: 18px;
    text-transform: capitalize;
    font-family: 'Campton-normal';
    color: #000000;
}

.singular label input {
    margin-right: 10px;
}

.package-booking .form-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;
}

.package-booking .form-row .form-group {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.package-booking .form-group input {
    background: rgba(241, 241, 241, 0.4);
    border-bottom: 0.3px solid rgba(0, 0, 0, 0.3) !important;
    border: 0;
    padding: 14px 22px;
    line-height: 1;
    font-size: 14px;
    height: auto;
}

.form-group input[type="submit"] {
    background: #0095DB;
    border: 0;
    height: 48px;
    color: #fff;
    max-width: 343px;
    width: 100%;
    padding-top: 2px;
  }
  

.border-btn {
    text-align: center;
  }
  

 .pagination ul li:nth-child(2)::before {
    opacity: 0;
}

.desc figure {
    margin: 0;
}

.desc {
    padding: 31.9px;
}

.tour {
    border: 0.914062px solid rgba(0, 0, 0, 0.3);
}

.tour article h5 {
    color: #21323C;
    font-size: 21px;
    margin-bottom: 12px;
}

.tour article h6 {
    color: #4E4439;
    font-size: 18px;
    line-height: normal;
}

.tour .list-item{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 28px;
    color: rgb(0 0 0 / 50%);
    border-bottom: 0.9px solid #B3B3B3;
    padding-bottom: 28px;
    font-weight: 700;
}

.day{
    margin-right: auto;
    padding-right: 15px;
}

.tour .list-item .fw-bold {
    color: #000;
    font-size: 21px;
}
.tour .highlight {
    color: #05A068;
}

.tour .list-group {
    padding: 31.9px 31.9px 31.9px;
    border-top: 0.9px solid #B3B3B3;
}

.tour .tour .list-item:last-child {
    border: 0;
    margin: 0;
    padding: 0;
}

@media (max-width: 575.98px){
    .form-row .form-group {
        width: 100%;
    }
    .form-row .form-group:first-child{
        margin-bottom: 15px;
    }
}

@media (min-width: 576px) and (max-width: 991.98px) { 
    .desc figure {
        text-align: right;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) { 
    .tour .list-item .fw-bold {
        font-size: 15px;
    }
}

@media (min-width:1200px) {
    .desc figure {
        text-align: right;
    }
}

@media (max-width:1199.98px){
    .pick-up, .packages, .package-booking, .desc, .tour .list-group {
        padding: 15px;
    }
}


