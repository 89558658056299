/*--banner-css--*/
.inner-banner-left {
    box-shadow: none;
    min-width: 698px;
    position: relative;
    z-index: 9;
    background: #ffffffe8;
    border-radius: 5px;
    padding: 17px 24px 40px;
    min-height: 423px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 10px 1px #dddddd5e;
  }
  .inner-banner-left p {
    margin: 32px 0 37px;
  }

  h2 {
    margin-bottom: 72px;
    position: relative;
    padding-bottom: 9px;
  }
  .head-line {
    text-align: center;
  }
  .head-line h2::before
  {
    position: absolute;
      left: 0;
      bottom: 0;
      height: 4px;
      width: 80px;
      content: "";
      border-radius: 30px;
      background: #FDC886;
  }
  .head-line h2 {
    margin: 0 auto;
    display: table;
  }

  .inner-banner-right img {
    width: 100%;
  }
  .banner-page img {
    width: 100%;
}
.select-property {
  /*position: absolute;*/
  bottom: 152px;
  min-height: 162px;
  max-width: 1550px;
  width: 100%;
}
.select-property ul {
  padding: 0;
  margin: 0;
  display: flex;
}
.select-property ul li {
  list-style: none;
}
.select-property ul li a {
  min-width: 170px;
  display: flex;
  padding: 16px 0;
  background: #fff;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  color: #000000d6;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 18px;
  align-items: center;
  opacity: 0.7;
}
.select-property ul li a img {
  max-width: 18px;
  height: auto;
  margin-right: 10px;
}
.select-property ul li.active a
{
  opacity: 1;
}
.page-full-data {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  margin-top: -93px;
}
.banner-wrapper {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  margin: 0 auto;
  max-width: 1350px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1550px) {
  .banner-wrapper {
    width: 80%;
  }
}
.banner-data-additionally {
  position: relative;
  margin-top: 175px;
  transform: translateY(100px);
  opacity: 0;
}
.banner-data-additionall-h1 {
  font-size: 500% !important;
  line-height: 100% !important;
  color: black;
}
.banner-data-additionall-p {
  color: black;
  font-size: 90% !important;
  font-weight: 400 !important;
  letter-spacing: 0.2px !important;
}
.banner-data-additionally p {
  color: white;
}
.banner-data-additionally-set-top {
  transform: translateY(0px);
  opacity: 1;
}
.select-property-additionally {
  position: relative;
  margin-bottom: 80px;
  margin-top: auto;
  bottom: 0;
  transform: translateY(100px);
  opacity: 0;
  z-index: 10;
}
.select-property-additionally-set-top {
  transform: translateY(0px);
  opacity: 1;
}
.home-main-container-additionally {

}
@media (max-width: 767px) {
  .banner-data-additionally {
    margin-top: 100px;
  }
  .select-property-additionally {
    margin-bottom: 280px;
  }
  .banner-wrapper {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    margin: 0 auto;
    max-width: 1350px;
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  .banner-data-additionall-h1 {
    margin-top: 10px !important;
    font-size: 200% !important;
    line-height: 100% !important;
    color: black;
  }
  .banner-data-additionall-p {
    margin-top: 5px !important;
    color: black;
    font-size: 90% !important;
    font-weight: 400 !important;
    letter-spacing: 0.2px !important;
  }
}