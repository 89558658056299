@font-face {
    font-family: 'Campton-light';
    src: url('Campton-Light.eot');
    src: url('Campton-Light.eot?#iefix') format('embedded-opentype'),
        url('Campton-Light.woff2') format('woff2'),
        url('Campton-Light.woff') format('woff'),
        url('Campton-Light.ttf') format('truetype'),
        url('Campton-Light.svg#Campton-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Campton-extralight';
    src: url('Campton-ExtraLight.eot');
    src: url('Campton-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Campton-ExtraLight.woff2') format('woff2'),
        url('Campton-ExtraLight.woff') format('woff'),
        url('Campton-ExtraLight.ttf') format('truetype'),
        url('Campton-ExtraLight.svg#Campton-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Campton-black';
    src: url('Campton-Black.eot');
    src: url('Campton-Black.eot?#iefix') format('embedded-opentype'),
        url('Campton-Black.woff2') format('woff2'),
        url('Campton-Black.woff') format('woff'),
        url('Campton-Black.ttf') format('truetype'),
        url('Campton-Black.svg#Campton-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Campton-Book';
    src: url('Campton-Bold.eot');
    src: url('Campton-Bold.eot?#iefix') format('embedded-opentype'),
        url('Campton-Bold.woff2') format('woff2'),
        url('Campton-Bold.woff') format('woff'),
        url('Campton-Bold.ttf') format('truetype'),
        url('Campton-Bold.svg#Campton-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Campton-normal';
    src: url('Campton-Book.eot');
    src: url('Campton-Book.eot?#iefix') format('embedded-opentype'),
        url('Campton-Book.woff2') format('woff2'),
        url('Campton-Book.woff') format('woff'),
        url('Campton-Book.ttf') format('truetype'),
        url('Campton-Book.svg#Campton-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Campton';
    src: url('Campton-Thin.eot');
    src: url('Campton-Thin.eot?#iefix') format('embedded-opentype'),
        url('Campton-Thin.woff2') format('woff2'),
        url('Campton-Thin.woff') format('woff'),
        url('Campton-Thin.ttf') format('truetype'),
        url('Campton-Thin.svg#Campton-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Campton-semibold';
    src: url('Campton-SemiBold.eot');
    src: url('Campton-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Campton-SemiBold.woff2') format('woff2'),
        url('Campton-SemiBold.woff') format('woff'),
        url('Campton-SemiBold.ttf') format('truetype'),
        url('Campton-SemiBold.svg#Campton-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Campton-medium';
    src: url('Campton-Medium.eot');
    src: url('Campton-Medium.eot?#iefix') format('embedded-opentype'),
        url('Campton-Medium.woff2') format('woff2'),
        url('Campton-Medium.woff') format('woff'),
        url('Campton-Medium.ttf') format('truetype'),
        url('Campton-Medium.svg#Campton-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Campton-extra-bold';
    src: url('Campton-ExtraBold.eot');
    src: url('Campton-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Campton-ExtraBold.woff2') format('woff2'),
        url('Campton-ExtraBold.woff') format('woff'),
        url('Campton-ExtraBold.ttf') format('truetype'),
        url('Campton-ExtraBold.svg#Campton-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Glitten';
    src: url('GlittenRegular.eot');
    src: url('GlittenRegular.eot?#iefix') format('embedded-opentype'),
        url('GlittenRegular.woff2') format('woff2'),
        url('GlittenRegular.woff') format('woff'),
        url('GlittenRegular.ttf') format('truetype'),
        url('GlittenRegular.svg#GlittenRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rollgates';
    src: url('Rollgates-Luxury.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: url('Raleway-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Cambay-Regular';
    src: url('Cambay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

