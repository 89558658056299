.product-slider-main {
    border-radius: 20px;
    overflow: hidden;
}
.product-slider-main .main-sider {
    width: 76%;
    float: left;
    padding-right: 20px;
}
.product-slider-main .side-sider {
    width: 24%;
    float: left;
    position: relative;
}
.product-slider-main .main-sider img {
    width: 100%;
    height: 522px;
    border-radius:0px;
    object-fit: cover;
}
h5.amp-loc {
    color: #4E4439;
    font-size: 18px;
    font-weight: 300;
    margin: 8px 0 19px;
}
.slider-desc h4 label {
    font-family: 'Campton-medium';
}
.slider-desc h4 label span {
    font-family: 'Campton-light';
    font-size: 24px;
}
.product-slider-main .side-sider img {
    width: 100%;
    height: 166px;
    object-fit: cover;
    border-radius: 0;
    margin-bottom: 3px;
}
.product-slider-main .side-sider .slick-track {
    display: flex;
    flex-direction: column !important;
}
.product-slider-main .side-sider .slick-track div {
    width: 100% !important;
}
.product-slider-main div {
    outline: none !important;
}
.more-hotos {
    position: absolute;
    bottom: 22px;
    height: 33px;
    background: #ffffff;
    width: auto;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 11px;
    padding: 21px 32px 16px;
    border-radius: 10px;
    border: 1px solid #000;
}
.more-hotos p {
    margin: 0;
    font-size: 17px;
    color: #000;
}
.product-detail-page {
    padding-top: 30px;
}
.inn-th-slide .slick-track .slick-slide {
    padding: 0 12px;
}
.slider-desc h4 {
    font-size: 27px;
    margin: 14px 0 4px;
}
/* ul.amnites {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
} */

ul.amnites {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    
}
ul.amnites.aminity-wh-img{
    background: #F7F7F7;
    padding: 35px 35px 5px 35px;
    margin: 14px 0px;
}

ul.amnites li {
    list-style: none;
    margin-right: 39px;
    color: #4E4439;
    font-size: 16px;
    margin-bottom: 0px;
    position: relative;
}
h5.amp-loc img {
    margin-right: 13px;
}
ul.amnites li:first-child::before {
    display: none;
}
ul.amnites-faeture li span {
    background: #EFE1D0;
    font-size: 16px;
    color: #0000008a;
    font-weight: 400;
    padding: 9px 20px;
}
ul.amnites-faeture li {
    list-style: none;
    margin-right: 20px;
}
ul.amnites-faeture {
    padding: 0;
    display: flex;
    margin-bottom: 47px;
}
.slider-desc h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Glitten;
}
.map img {
    width: 100%;
    margin-bottom: 9px;
}
ul.amnites.aminity-wh-img li::before {
    display: none;
}
ul.amnites.aminity-wh-img {
    margin-bottom: 30px;
}
ul.amnites.aminity-wh-img li {
    margin-right: 35px;
    margin-bottom:30px;
    font-size: 20px;
    line-height: 33px;
    color: #333333;
    opacity: 0.8;
    display: flex;
    align-items: center;
}
ul.amnites li::before {
    content: "";
    position: absolute;
    left: -21px;
    top: 7px;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background: #4E4439;
}
ul.amnites li img {
    margin-right: 10px;
    max-width: 25px;
}
ul.list-gyr {
    padding: 0;
    margin-bottom: 41px;
}
/* ul.list-gyr li {
    font-size: 16px;
    color: #00000091;
    margin-bottom: 5px;
    list-style: none;
} */

ul.list-gyr li {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    opacity: 0.8;
    font-family: 'Campton-light';
    list-style: none;
}
.list-dot .list-s-none{list-style: none;}

.you-like-slider {
    margin-top: 36px;
    margin-bottom: 0px;
    padding-top: 30px;
    display: table;
    width: 100%;
}

.you-like-slider .iner-rental p {
    margin-bottom: 0;
    font-size: 13px;
    font-family: 'Campton-medium';
    max-width: 100%;
}
.you-like-slider h4 {
    font-size: 20px;
    margin-bottom: 0px;
    font-family: 'Campton-medium';
}
.slider-desc p {
    margin-bottom: 30px;
    opacity: .8;
}
.you-like-slider button.slick-arrow.slick-next {
    position: absolute !important;
    top: 34%;
    background: #fff;
    bottom: -10px;
    right: -40px;
    width: 40px;
    height: 40px;
    display: flex !important;
    z-index: 99;
    border-radius: 100px;
    border: 1px solid #979797;
    align-items: center;
    justify-content: center;
}
.you-like-slider button.slick-arrow.slick-prev::before {
    width: 11px;
    height: 11px;
    display: flex;  
    position: absolute;
    left: 15px;
}
.you-like-slider button.slick-arrow.slick-next::before {
    width: 11px;
    height: 11px;
    display: flex;  
    position: absolute;
    right: 15px;

}
.you-like-slider button.slick-arrow.slick-prev {
    position: absolute !important;
    top: 34%;
    background: #fff;
    bottom: -10px;
    right: inherit;
    width: 40px;
    height: 40px;
    display: flex !important;
    z-index: 99;
    border-radius: 100px;
    border: 1px solid #979797;
    align-items: center;
    justify-content: center;
    left: -40px;
}
.you-like-slider .slick-list .slick-slide {
    position: relative;
}
.you-like-slider h3 {
    font-family: 'Glitten';
    font-size: 50px;
}

.product-slider-main button.slick-arrow.slick-next {
    right: 20px;
}
.product-slider-main button.slick-arrow.slick-prev
{
    left: 20px;
}
.product-slider-main button.slick-arrow {
    background: #ffffffc9;
    z-index: 9;
    width: 40px;
    height: 40px;
    border-radius: 100px;
}
.product-slider-main .side-sider .slick-slide:last-child img {
    border-radius: 0 0 20px 0px;
}
button.slick-arrow.slick-prev::before {
    width: 12px;
    height: 12px;
    border: 3px solid #000000;
    position: absolute;
    top: 15px;
    left: 16px;
    border-top: 0;
    border-left: 0;
    content: "" !important;
    transform: rotate(133deg);
}
button.slick-arrow.slick-next::before {
    content: "";
    width: 12px;
    height: 12px;
    border: 3px solid #252525;
    display: flex;
    border-top: 0;
    border-left: 0;
    content: "" !important;
    transform: rotate(133deg);
    transform: rotate(-45deg);
    position: absolute;
    top: 14px;
    left: 11px;
    opacity: 1 !important;
}