.container {
  position: relative;
  margin-top: 15px;
}
.wrapper {
  position: relative;
  margin-top: 10px;
  height: 100%;
  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  align-items: center;
}
.block {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  font-size: 70%;
}
.block span {
  font-weight: 500;
  font-size: 105%;
  opacity: 0.5;
  color: #4A4945 !important;
}
.block h6 {
  font-size: 100%;
  font-weight: 600;
  color: #4A4945 !important;
}
@media (max-width: 767px) {
  .wrapper {
    grid-gap: 20px;
  }
}