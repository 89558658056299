.headerpage {
    padding: 15px 0;
    z-index: 10;
    width: 100%;
    position: relative;
    background: #f1f1f1;
}
.container-additionally {
    position: relative;
    max-width: 1350px !important;
    width: 80% !important;
    padding: 0 !important;
}
.headerpage ul.navbar-nav.me-auto {
    margin: 0 auto;
}
.headerpage ul.navbar-nav.me-auto li a {
    font-size: 18px;
    text-decoration: none;
    margin: 0 20px;
    color: #000;
    position: relative;
}
a.navbar-brand {
    color: #000;
}
.log-btn button {
    font-size: 18px;
    border: 0;
    background: #fff;
    box-shadow: 0 0 10px 1px #ddddddbd;
    padding: 8px 31px;
}
.headerpage ul.navbar-nav.me-auto li a::before {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 0%;
    transition: 0.5s;
    height: 3px;
    background: #0095DB;
    border-radius: 10px;
}
.headerpage ul.navbar-nav.me-auto li a:hover::before {
    width: 100%;
    transition: 0.5s;
}
.banner-page img {
    width: 100%;
}

.banner-page {
    /* max-width: 1552px; */
    position: relative;
    height: 700px;
    margin: 0 auto;
}
.banner-page:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 0;
    box-shadow: 0px -20px 213px 100px white;
}
.banner-data {
    top: 50px !important;
    /*position: absolute;*/
    /*top: 162px;*/
    /* transform: translateY(-50%); */
}

.banner-data p {
    font-size: 18px;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-style: italic;
    margin: 19px 0 0;
}
.banner-page {
    position: relative;
    /*background: red;*/
    transition: 0.3s;
    background-repeat: no-repeat;
    background-position: top center;
    overflow: hidden;
}
.banner-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.69) 9.16%, rgba(0, 0, 0, 0.00) 61.19%);
    width: 100%;
    height: 100%;
    transform: unset;
}
.top-property {
    margin-top: 0px;
}
@media (max-width: 767px) {
    .headerpage {
        padding: 10px 0;
        z-index: 10;
        width: 100%;
        position: relative;
        background: #f1f1f1;
    }
    .container-additionally {
        position: relative;
        max-width: 1350px !important;
        width: 90% !important;
        padding: 0 !important;
    }
    .banner-page {
        position: relative;
        height: 700px;
    }
    .banner-page:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 0;
        box-shadow: 0px 20px 400px 400px #fffffff5;
    }
    .banner-page:after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(255, 255, 255, 0.41);
        width: 100%;
        height: 100%;
    }
}