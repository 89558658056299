.container {
  display: flex;
  flex-direction: column;
  color: black;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  text-decoration: none !important;
}
.container p {
  margin: 0;
}
.container-initial {
  opacity: 1;
  visibility: visible;
}
.container h5 {
  font-family: Cambay-Regular, sans-serif !important;
  margin-top: 20px;
  font-size: 100%;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  color: #4E4439 !important;
}
.container:hover {
  color: black;
}
.content-location {
  font-family: Raleway, sans-serif !important;
  font-weight: 400;
  margin-top: 10px;
  font-size: 80%;
  line-height: 19px;
  letter-spacing: 0em;
  color: #4E4439 !important;
}
.photo-block {
  position: relative;
  overflow: hidden;
  background: grey;
  border-radius: 15px 15px 0 0;
}
.back-photo {
  position: relative;
  width: 100%;
}
.main-photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.book-now {
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;
}
.book-now-fon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.7;
}
.book-now-content {
  position: relative;
  color: white;
  font-weight: 500;
  font-size: 100%;
  margin: 0 !important;
}
.container:hover .book-now {
  bottom: 0;
}
.glare-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  overflow: hidden;
}
.glare-item {
  opacity: 0.7;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-150%, -50%) rotateZ(-45deg);
  width: 200%;
  height: 1px;
  background: #f3c791 !important;
  animation: loadingAnimation 1.5s ease-in-out infinite;
  box-shadow: 0 0 150px 50px #0095DB;
}

@keyframes loadingAnimation {
  0% {
    transform: translate(-150%, -50%) rotateZ(-45deg);
  }
  100% {
    transform: translate(50%, -50%) rotateZ(-45deg);
  }
}
@media (max-width: 767px) {
  .container:hover .book-now {
    bottom: -67px;
  }
  .container h5 {
    margin-top: 15px;
    font-size: 90%;
    font-weight: 600;
    margin-bottom: 5px !important;
  }
}