.main-swiper-list_pag .swiper-pagination{
  bottom: 0px !important;
}
.main-swiper-list_pag .swiper-pagination-bullet {
  border-radius: 0px;
  width: 18px;
  height: 2px;
  background: white;
}
.main-swiper-list_pag .swiper-pagination-bullet-active {

}
.main-swiper-list_pag .swiper-pagination{
  bottom: 0px !important;
}
@media (min-width: 767px) {
  .main-swiper-list_pag .swiper-pagination{
    /*display: none !important;*/
  }
}