.user-icon p{margin-bottom:5px;
font-weight: 400;
font-size: 16px;
line-height: 21px;
color: #000000;
opacity: 0.5;}
.user-icon h3{margin-bottom:7px;
font-style: normal;
font-weight: 500;
font-size: 21px;
line-height: 21px;
color: #000000;}

.booked-img-height{
    height: 250px;
    width: 320px;
}

.user-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left:15px;
}
.user-img {
    position: relative;
    padding-left: 60px;
    margin-bottom:20px;
}

.img-profile-size {
    height: 59px;
    width: 59px;
    overflow: hidden;
    border-radius: 50%;
    position: absolute;
    left: 0px;
    top: -5px;
}
.img-profile-size img{
    height: 100%;
    width: 100%;
   object-fit: cover;
   border-radius: 50%;
}
.user-dash{padding:60px 0px;}
.booking-list li {
    position: relative;
    padding: 10px 42px 10px 46px;
    margin-bottom: 10px;
    display: table;
    width: 100%;
}.product-liating-page {
    padding: 40px 0;
}

.booking-list li a{font-weight: 600;text-decoration: none;
font-size: 21px;
line-height: 24px;
font-family: 'Campton';
color: #000000;}
.book-pos {
    font-size: 17px;
    color: #000;
    position: absolute;
    left: 14px;
    top: 13px;
}
.booking-list li.active{
    background: #0095DB;
   
}
.booking-list li.active a{ color:#fff;}
.booking-list li.active .book-pos {
    color: #fff;}

.listing-sidebar{ 
    padding: 20px 0px;
    height: auto;
    overflow: auto;
}
.border-right{border-right: 1px solid rgba(0,0,0,.3);}
.product-text .l-text h5{
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 21px;
color: #21323C;
margin:0px;
text-decoration: none;

}
.product-text {
    display: flex;
}
.product-text{list-style: none;}
.product-text p{
    padding-right:0px;
    margin-bottom: 0px;
    text-decoration: none;
    font-weight: 400;
font-size: 12px;
line-height: 16px;
color: #4D4D4D;
}

.w-40.right-t{text-align:right;}
.product-text{padding: 10px 0px;}
.w-40.right-t h6{margin-bottom:0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: #21323C;}
.user-dash a{text-decoration: none; color: black }
.w-60{width: 60%;}
.w-40{width: 40%;}


/* about us css */

.head1{
    font-size: 26px;color:#000;
    font-weight: 600;
    margin-bottom:20px;
}

.about-img-size{height: 300px;width: 100%;overflow: hidden;}
.about-img-size img{height: 100%;width: 100%;object-fit: cover;}
.content-about p{
    font-size: 16px;margin-bottom: 15px;line-height: 22px;
}
.content-about h3{
    font-size:18px;margin-bottom: 10px;line-height: 22px;font-weight: 600;
}

.about-sec{padding:60px 0px;}
.outer-notif{
    background-color: #fff;border-radius: 8px;padding:15px 13px;
    display: flex;
    border: 1px solid #ececec;
 
}
.img-notif{
    height: 50px;width: 50px;border-radius: 6px;
}
.img-notif img {
    height: 100%;
    width: 100%;
    border-radius: 6px;
    object-fit: cover;
    box-shadow: 0 0 10px 1px #dddddd8a;
    border-radius: 100px;
}

.content-notif h6{font-size: 16px;font-weight: bold;margin-bottom:2px;}
.content-notif p{font-size: 16px;margin-bottom:3px;}

.content-notif {
    width: calc(100% - 60px);
    padding-left: 15px;
    position: relative;
}
.notif-nav li{margin-bottom:15px;}































