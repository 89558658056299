.container {
  position: relative;
  width: 100%;
}
.container h4 {
  position: relative;
  font-size: 140%;
  margin-bottom: 20px;
  margin-top: 47px;
  text-transform: lowercase;
  text-align: center;
}
.container h4::first-letter{
  text-transform: uppercase;
}
.loader-container {
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
.block {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px 35px;
}
.swiper-container-mobil-home {
  padding: 0 5% !important;
}
.wrapper-mobil {
  width: 100%;
}
.arrow-mobil {
  position: absolute;
  color: #0095DB;
  top: 0;
  right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
}
.arrow-mobil p{
  color: #0095DB;
  font-size: 80%;
  font-weight: 500;
  margin: 0 !important;
}
.arrow-mobil span{
  transform: translateY(0px) scale(0.8, 1.4);
  color: #0095DB;
}

@media (max-width: 1024px) {
  .block {
    grid-template-columns: 1fr 1fr;
  }
  .container h4{
    position: relative;
    font-size: 120%;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .container h4{
    padding-left: 5%;
  }
}