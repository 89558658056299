.price-table {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0px;
  padding: 27px;
  position: sticky;
  top: 50px;
  margin-top: 20px;
} 
.price-head {
  display: flex;
  align-items: center;
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 10px;
  justify-content: space-between;
}
.product-detail-page .price-head h6 {
  color: #4E4439;
  font-size: 21px;
  margin-top: 20px;
  font-size: 16px;
  color: #0095DB;
  margin: 0 0 16px;
}
.price-table .form-group label {
  font-size: 16px;
  color: #131415cc;
  margin-bottom: 5px;
  width: 100%;
}
input#number {
  max-width: 70px;
  text-align: center;
}
.counter span {
  width: 30px;
  height: 30px;
  display: flex;
  border: 1px solid #a2a2a2;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}
.price-table .form-group .counter input {
  height: 30px;
  border: 0;
  color: #000;
  appearance: none;
  -webkit-appearance: none;
  -webkit-appearance: none;
  padding-left: 26px;
}
.counter {
  display: flex;
  align-items: center;
}
.price-table .form-group input {
  height: 48px;
  border: 1px solid #0095DB;
  width: 100%;
  border-radius: 0;
  padding: 0 12px;
  color: #909090;
  outline: none;
}
.price-table .form-group {
  margin-bottom: 18px;
}
.price-table .form-group input[type="submit"] {
  background: #f6bc8d;
  color: #000;
  border: 0;
}
.form-group.submit {
  border-top: 0;
  padding-top: 3px;
  width: 100%;
}
.data-frm {
  box-shadow: 0 0 10px 1px #ddd;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}
.form-group.submit button {
  background: #0095DB;
  border: 0;
  height: 48px;
  color: #fff;
  width: 100%;
  padding-top: 2px;
}
.page-modal h4 {
  font-family: "Glitten";
  font-size: 40px;
  color: #4e4439;
  margin-bottom: 35px;
}
.page-modal form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.page-modal {
  padding: 26px 40px;
}
.error {
  font-size: 14px;
  color: red;
}
.modal-header {
  border: 0;
  padding: 0;
}
.modal-header button {
  padding: 0;
  border: 0;
  position: absolute;
  right: 50px;
  top: 42px;
  border: 2px solid #00000063;
  width: 28px;
  height: 28px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  z-index: 9;
}
.modal-header button {
  padding: 0 !important;
  margin: 0 !important;
}

.page-modal form .from-group {
  width: 100%;
  margin-bottom: 18px;
}
.page-modal form .from-group label {
  font-size: 14px;
  color: #00000094;
  width: 100%;
  font-weight: 400;
}
.page-modal form .from-group input {
  height: 48px;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #00000017;
  background: #f1f1f166;
  padding: 0 14px;
  color: #949494;
  font-weight: 400;
  font-size: 15px;
}
.guest-dropdown {
  display: flex;
  justify-content: space-between;
}

/* ---------mm-------- */
.tours-cards-inner {
  margin-bottom: 15px;
}
.mb-100 {
  margin-bottom: 100px;
}
