.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto; /* Изменим высоту на 'auto' для автоматической высоты контейнера */
}

.wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 76% 24%;
}

.swiperMain {
  position: relative;
  padding-right: 3.5%;
  flex-grow: 1;
  height: 100%; /* Добавим высоту 100% */
}

.slickMain {
  width: 100% !important;
  position: relative;
  overflow: hidden;
  height: 100%; /* Добавим высоту 100% */
  cursor: grab;
}
.slickMain:active {
  cursor: grabbing;
}

.slickMainBack {
  position: relative;
  width: 100%;
}

.slickMainFront {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.slickMainFrontBlur {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(5);
  width: 100%;
  filter: blur(7px);
}
.swiperLittle {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 4%;
}
.swiperLittle button:hover {
  opacity: 0.6;
}
.swiperLittle button {
  position: relative;
  overflow: hidden;
  padding: 0 !important;
  transition: 0.3s;
}
.slickLittle {
  height: 100% !important;
  position: relative;
  overflow: hidden;
}

.slickLittleBack {
  position: relative !important;
  transform: none !important;
  top: 0 !important;
  left: 0 !important;
  height: 100%;
}

.slickLittleFront {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

@media (max-width: 767px) {
  .swiperMain {
    padding-right: 0%;
  }
  .wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1%; /* Добавим небольшой зазор между колонками */
  }
  .swiperLittle {
    display: none;
  }
}
.swiperListLittleCard {
  position: relative;
  width: 100%;
}
.swiperListLittleCardFront {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
}
.swiperListLittleCardFrontBlur {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(3);
  background: white;
  filter: blur(7px)
}
.moreBlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(250, 242, 233, 0.76);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}
.moreBlock p {
  position: relative;
  font-size: 120%;
  color: black;
}
.fullscreenContainer {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.64);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  transition: 0.2s ease;
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  grid-gap: 10px;
}
.fullscreenContainerClose {
  position: absolute;
  right: 30px;
  top: 30px;
  color: white;
  z-index: 100;
  font-size: 150%;
  font-weight: 100;
  transition: 0.2s ease-in-out;
}
.fullscreenContainerClose:hover {
  opacity: 0.5;
}
.closeFon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #0000004f;
  backdrop-filter: blur(3px);
}
.loaderMainSwiper {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%) scale(0.7);
}
.fullscreenContainerOff {
  visibility: hidden;
  opacity: 0;
}
.swiperFull {
  position: relative;
  width: 100%;
  height: 100% !important;
  flex-grow: 1;
}
.glare-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  overflow: hidden;
}
.glare-item {
  opacity: 0.7;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-150%, -50%) rotateZ(-45deg);
  width: 200%;
  height: 1px;
  background: #f3c791 !important;
  animation: loadingAnimation 1.5s ease-in-out infinite;
  box-shadow: 0 0 150px 50px #0095DB;
}
@keyframes loadingAnimation {
  0% {
    transform: translate(-150%, -50%) rotateZ(-45deg);
  }
  100% {
    transform: translate(50%, -50%) rotateZ(-45deg);
  }
}
.swiperFullMainSlide {
  position: relative;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: grab;
  width: 100%;
  height: 100%;
}
.swiperFullMainSlide:active {
  cursor: grabbing;
}
.swiperFullMainSlide img{
  position: relative;
  height: 100%;
}
.swiperFullBottomBlock {
  margin: 0 auto;
}
.swiperFullBottomBlock .swiper-slide-thumb-active{
  opacity: 0;
}
.swiperFullBottomBlockSlide {
  position: relative;
  cursor: pointer;
  width: 100px !important;
  overflow: hidden;
  height: 100px !important;
  transition: opacity 0.3s !important;
}
.swiperFullBottomBlockSlide:hover {
  opacity: 0.7;
}
.swiperFullBottomBlockSlide img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}
@media (max-width: 767px) {
  .swiperFullMainSlide img{
    height: auto;
    width: 100%;
  }
  .fullscreenContainer {
    padding: 0px 0;
    justify-content: center;
  }
  .swiperFull {
    position: relative;
    width: 100%;
    flex-grow: initial;
  }
  .swiperFullBottomBlock {
    width: 290px;
    height: 100px;
  }
  .swiperFullBottomBlockSlide {
    width: 50px !important;
    height: 50px !important;
  }
  .fullscreenContainerClose {
    position: absolute;
    right: 15px;
    top: 15px;
    color: white;
    z-index: 100;
    font-size: 150%;
    font-weight: 100;
  }
}