.container {
  position: sticky;
  bottom: 0;
  background: #fff;
  left: 0;
  right: 0;
  padding: 15px 0 10px;
  z-index: 9;
  box-shadow: 0 0 10px 1px #dddddd6e;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
}
@media (min-width: 767px) {
  .container {
    display: none;
  }
}