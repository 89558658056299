@media (max-width:1700px)
{
    .overlay-text {
        max-width: 1310px;
    }
   
    .container {

    }
    .bnr-form {
        position: relative;
        right: 50px;
    }
   
    ul.amnites.aminity-wh-img li {
        margin-bottom: 15px;
    }
    .inn-th-slide {
        max-width: 95%;
        margin: 0 auto;
    }
    .you-like-slider .inner-propery img {
        height: 190px;
    }
    .you-like-slider .inner-propery h5 {
        font-size: 17px;
    }
    img.ft-img-four {
        right: -30px;
        max-width: 23%;
    }
    img.ft-img-two {
        right: 110px;
    }
}
@media (max-width:991px) {
.agent-modal .modal-dialog {
    max-width: 70% !important;
}
.tours-slide {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.tours-slide span {
    margin: 0 5px 10px;
}
.register-property h2 {
    font-size: 50px;
    line-height: 51px;
}
.footer {
    padding: 40px 20px 17px;
}
img.ft-img-one {
    max-width: 24%;
}
img.ft-img-two {
    right: 50px;
    max-width: 20%;
}
img.ft-img-three {
    left: 20px;
    max-width: 22%;
}
img.ft-img-four {
    right: 0;
    max-width: 20%;
}
h2 {
    font-size: 60px;
    line-height: 70px;
}
.seacrh-bn {
    padding: 21px 21px;
}
.select-property {
    max-width: 100%;
}
.srch-btn button {
    font-size: 16px;
    padding: 18px 17px;
}
.seacrhtime .srchtab::before {
    left: -45px;
}
.banner-page h1 {
    font-size: 85px;
    line-height: 84px;
}
}
@media (max-width:767px) {
    .select-property {
        /* display: none !important; */
        position: static;
    }
    .bookng-table-cart table {
        width: 150%;
    }
    .bookng-table-cart {
        overflow-x: auto;
    }
    ul.amnites.aminity-wh-img {
        padding: 21px 0px 5px 3px;
    }
    .about-sec {
        padding: 30px 0px;
    }
    ul.amnites li img {
        margin-right: 7px;
        max-width: 20px;
    }
    
    body {
        padding-bottom: 90px;
    }
     .our-app, .footer, .mob-tour.mob-servi {
        display: none;
    }
    .you-like-slider {
        display: initial;
    }
    .price-table .outer-plus {
        display: flex;
    }
    .agent-modal .modal-dialog {
        max-width: 100% !important;
        position: absolute;
        left: 0;
        right: 0;
    }
    .reviewlist ul li p {
        font-size: 15px;
        padding-right: 0;
    }
    .agent-modal .modal-dialog {
        top: 0 !important;
        transform: translate(0px, 0px) !important;
    }
    .reverse-order .col-lg-9.col-xl-8.col-xxl-7 {
        order: 2;
        margin: 26px 0;
    }
    .chat-message {
        bottom: 88px;
        right: 0;
        min-width: 98%;
    }
    .select-property ul li a {
        text-align: left;
        justify-content: flex-start;
        padding: 14px 30px !important;
    }
    .tour-services.my-tours {
        padding: 23px 0;
    }
    .inner-propery {
        position: relative;
        left: 5px;
    }
    .tours-cards.mb-100{
        margin-bottom: 20px;
    }
    .price-table {
        padding: 15px;
    }
    .reviewlist ul li {
        width: 100%;
        margin: 0 0 24px;
        padding: 0;
    }
    .review-lit-iner h5 {
        font-size: 16px;
    }
    .ranger-review {
        width: 100%;
    }
    .reviewlist ul {
        margin: 28px 0 0;
    }
    .rating-cat label.star::before {
        font-size: 21px;
    }
    .rating-cat .from-group {
        width: 100%;
    }
    .show-more {
        margin: 11px 0 60px;
        float: left;
    }
    .ranger-review span {
        width: 92%;
    }
    .review-lit-iner {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin: 5px 0;
        flex-direction: column;
    }
    body .reviewlist h4 {
        font-size: 21px;
        margin-bottom: 19px;
    }
    .footer-bar {
        display: block;
    }
    .product-detail-page p {
        font-size: 16px;
        line-height: 23px;
    }
    .dropdown-menu.show {
        left: -40px;
    }
    .booking-det-left {
        flex-wrap: wrap;
    }
    .book-main-img {
        width: 100%;
        margin-right: 0;
    }
    .booking-detail h5 {
        font-size: 19px;
    }
    .slick-list .slick-slide.slick-active.slick-cloned p.error-msg {
        display: none !important;
    }
    .booking-det-right {
        max-width: 100%;
        padding: 18px 0;
        margin-top: 20px;
    }
    .tour .list-item .fw-bold {
        font-size: 17px;
    }
    .inner-date {
        padding: 0 7px;
    }
    .booking-det-right h6 {
        padding: 0 19px;
        font-size: 17px;
    }
    .agent-modal .modal-dialog {
        max-width: 100% !important;
    }
    .inner-date h4 {
        font-size: 18px;
    }
    .inner-date span {
        font-size: 14px;
    }
    .modal-title {
        font-size: 28px;
        margin-left: 0;
    }
    .payment-tab-head h4 {
        font-size: 15px;
    }
    .payment-tab-head span {
        font-size: 13px;
    }
    .bookng-table-cart tr td, .bookng-table-cart tr th {
        font-size: 15px;
        padding: 8px 15px;
    }
    .modal-dialog.review-modal {
        max-width: 93%!important;
        margin: 0 auto;
    }
    .filter-tab ul li span {
        padding: 8px 0 7px;
        font-size: 14px;
        }
        a {
            text-decoration: none;
        }
        .filter-tab ul li {
        width: calc(100% / 3);
        }
        
        .filter-tab ul {
        padding-left: 0;
        }
    .view-all-btn {
        margin: 16px 0;
    }
    .banner-data {
        position: relative;
        color: white;
    }
    .seacrhtime .srchtab span input {
        background: transparent;
    }
    .product-slider-main {
        margin-bottom: 0;
    }
    .product-slider-main button.slick-arrow.slick-prev::before {
        border-color: #000;
        left: 12px;
        top: 11px;
    }
    .product-slider-main button.slick-arrow.slick-prev {
        left: 10px !IMPORTANT;
        top: 50%;
        right: initial;
        width: 32px;
        height: 32px;
    }
    .product-slider-main button.slick-arrow.slick-next::before {
        border-color: #000;
        left: 9px;
        top: 11px;
    }
    .product-slider-main {
        border-radius: 0;
    }
    .product-slider-main button.slick-arrow.slick-next {
        left: inherit !IMPORTANT;
        top: 50%;
        right: 10px;
        width: 32px;
        height: 32px;
    }
    .product-slider-main button.slick-arrow {
        display: block !important;
    }
    .modal-images div img {
        padding: 2px;
    }
    .select-property ul {
        flex-direction: column;
        margin: 14px 0 0;
    }
    .seacrh-bn form {
        flex-direction: column;
    }
    .modal-header button.btn-close {
        top: -5px;
        right: -5px;
        width: 30px;
        height: 30px;
    }
    .seacrhtime .srchtab span input {
        width: 100%;
    }
    .seacrhtime .srchtab::before {
        opacity: 0;
    }
    .seacrhtime .srchtab span {
        display: flex;
        align-items: center;
    }
    .srch-btn {
        width: 100%;
    }
    .srch-btn button {
        padding: 13px 54px;
        width: 100%;
        background: transparent;
        color: #0095DB;
        border: 1px solid #0095DB;
    }
    .seacrhtime .srchtab {
        width: 100%;
        margin-bottom: 25px;
    }
    .seacrhtime {
        width: 100%;
        flex-direction: column;
    }
    .seacrh-bn {
    
        padding: 20px 15px;
        margin: 30px 0;
        flex-direction: column;
        border-radius: 10px;
    }
    .price-table .form-group input {
        appearance: none;
        background: #fff;
    }
    .inn-th-slide .slick-track .slick-slide {
        padding: 0 8px;
    }
    .you-like-slider .inner-propery img {
        height: 140px;
    }
    .pagination ul li a {
        font-size: 15px;
    }
    iframe#iframeId {
        height: 230px !important;
    }
    .pagination ul li {
        margin-right: 12px;
    }
    .pagination ul li::before {
        left: -11px;
        top: 0px;
    }
    .you-like-slider button.slick-arrow.slick-prev, .you-like-slider button.slick-arrow.slick-next
    {
        display: none !important;
    }
    .banner-page h1 {
        font-size: 29px;
        line-height: 56px;
        color: #000;
        margin: 0;
    }
    .banner-page h1 br {
        display: none;
    }
    .footer {
        overflow: hidden;
    }
    button.slick-arrow {
        display: none !important;
    }
    .container-fluid {
        padding: 0 !important;
    }
    .headerpage .log-btn button {
        font-size: 15px;
        padding: 6px 7px;
        border-radius: 3px;
    }
    .headerpage button.navbar-toggler {
        background: #fff;
        padding: 2px 4px;
        border: 0;
        box-shadow: none;
        outline: none;
        position: absolute;
        right: 0;

    }
    .mbile-slider .slick-track .slick-slide,.explore-data .slick-track .slick-slide, .mobile-car .slick-track .slick-slide, .mob-tour .slick-track .slick-slide, .blog-slider .slick-track .slick-slide {
        padding: 0 8px;
    }
    .inner-propery p {
        font-size: 13px;
        font-weight: 400;
        padding-right: 0;
        line-height: 19px;
    }
    .mob-tour .inner-ser h4 {
        font-size: 15px;
        margin-top: 12px;
        margin-bottom: 5px;
    }
    .mob-tour .inner-ser a {
        color: #0095DB;
        text-decoration: none;
        font-size: 14px;
    }
    .inner-propery {
        margin-bottom: 0;
    }
    .inner-propery h5 {
        font-size: 15px;
        margin-top: 8px;
        margin-bottom: 4px;
    }
    .mob-tour h4 {
        margin-bottom: 20px;
    }
    .mob-tour {
        padding-bottom: 16px;
    }
    .inner-ser p {
        font-size: 13px;
        line-height: 17px;
        margin-bottom: 6px;
        min-height: 71px;
    }
    .inner-propery img {
        height: 150px;
    }
    .log-btn {
        padding-right: 0px;
    }
    body .banner-page {
        min-height: 300px;
        background-size: cover;
    }
    .banner-data p {
        margin: 0;
        color: #000;
        font-size: 15px;
    }
    .banner-data {
        top: 122px;
    }
    div#navbarSupportedContent {
        position: absolute;
        top: 43px;
        left: 0;
        right: 0;
        background: #fff;
        padding: 13px 0 6px 1px;
    }
    .headerpage ul.navbar-nav.me-auto li a {

        color: #5a5a5a !important;
    }
    .mob-tour {
        padding-top: 18px;
    }
    .blogs.property {
        padding-top: 0px;
        padding-bottom: 25px;
    }
   body h4 {
        font-size: 17px;
    }
    .mbile-slider .slick-track,.expolre-list .slick-track, .explore-data-mobile .slick-track, .mobile-car .slick-track, .mob-tour .slick-track, .blog-slider .slick-track  {
        position: relative;
        left: 13px;
    }
    .explore-inner {
        padding: 0;
    }
    .tour-services.property {
        padding-top: 15px;
    }
    .property h4 {
        font-size: 17px;
        font-weight: 600;
        text-align: left;
    }
    .explore-data-mobile .slick-track .slick-slide {
        padding: 0 8px;
    }
    h4.common-heading {
        margin-bottom: 9px;
        font-size: 17px;
        margin-top: 21px;
    }
    .explore-data-mobile {
        margin-bottom: 20px;
    }
    .top-property-mob {
        margin-top: 11px;
    }
    .services {
        padding-top: 30px;
    }
    h2 {
        font-size: 29px;
        line-height: 33px;
    }
    .services-left p {
        font-size: 16px;
    }
    br
    {
        display: none;
    }
    .services-right {
        padding: 15px 0 0;
        border: 0;
    }
    .services-right .inner-ser {
        width: 100%;
    }
    .services-right {
        flex-wrap: wrap;
        text-align: center;
    }
    .services-right .inner-ser p {
        font-size: 15px;
        padding-right: 0;
        line-height: 22px;
    }
    .services-right .inner-ser a {
        font-size: 16px;
    }
    .inner-ser h4 {
        font-size: 18px;
    }
    .services-right .inner-ser {
        margin-bottom: 34px;
    
    }
    .car-services.property {
        padding-top: 10px;
    }
    .our-app {
        padding-bottom: 30px;
        padding-top: 10px;
    }
    .our-app h3 {
        font-size: 22px;
        margin-bottom: 5px;
    }
    .our-app p {
        font-size: 16px;
        margin: 0px auto 16px;
        line-height: 21px;
    }
    img.frame-one, img.frame-two {
        display: none;
    }
    .playstore {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .our-app .inner-play {
        padding-bottom: 0;
    }
    .register-property img {
        display: none;
    }
    .register-property {
        padding-bottom: 30px;
        padding-top: 0;
        margin-bottom: 0px;
    }
    .register-property h2 {
        font-size: 29px;
        line-height: 34px;
        margin-bottom: 2px;
    }
    .register-property p {
        font-size: 16px;
        line-height: 20px;
        margin: 0 auto 18px;
    }
    .footer {
        padding: 40px 0px 100px;
    }
    .register-property a {
        font-size: 16px;
    }
    .fotter-menu {
        padding-top: 14px;
        padding-bottom: 10px;
    }
    .footer-left a {
        font-size: 28px;
        padding-bottom: 8px;
    }
    .playstore img {
        max-width: 130px;
    }
    .footer-left {
        padding-right: 0;
    }
    .footer-menu h5 {
        font-size: 19px;
        margin-bottom: 9px;
        margin-top: 15px;
    }
    .footer-menu.social {
        padding-top: 13px;
    }

    .lower-fr ul li {
        margin-right: 8px;
    }
    .lower-fr ul li a, .lower-fr p {
        font-size: 13px;
    }
    .services-right .inner-ser p {
        min-height: auto;
    }
    .car-details {
        display: none;
    }
    .blogs.property .inner-propery {
        padding: 0;
    }
    button.slick-arrow.slick-prev::before {
        width: 11px;
        height: 11px;
        border: 2px solid #0095DB;
        border-top: 0;
    border-left: 0;
    }
    button.slick-arrow.slick-next::before {
        width: 11px;
        height: 11px;
        border: 2px solid #0095DB;
        border-top: 0;
    border-left: 0;
    }
    button.slick-arrow.slick-next {
        right: -6px;
    }
    button.slick-arrow {
        display: block !important;
        position: absolute;
        top: -22px;
        right: 13px;
        left: inherit !important;
    }
    .mob-tour {
        padding-bottom: 41px;
    }
    .product-slider-main .main-sider {
        width: 100%;
        padding-right: 0;
    }
    .product-slider-main .side-sider {
        width: 100%;
        display: none;
    }
    .product-slider-main {
        flex-wrap: wrap;
    }
    .product-slider-main .main-sider img {
        height: 240px;
        border-radius: 10px;
        object-fit: cover;
    }
    .slider-desc h4 {
        flex-wrap: wrap;
    }
    .slider-desc h4 {
        font-size: 20px;
        margin: 14px 0 4px;
    }
    .slick-slider button {
        display: none !important;
    }
    .price-table button {
        display: block !important;
    }
    .product-slider-main .main-sider ul.slick-dots li {
        width: 21px;
        background: #ffffffa3;
        height: 3px;
    }
    .product-slider-main .main-sider ul.slick-dots li.slick-active {
     
        background: #fff;
     
    }
    .product-slider-main .main-sider ul.slick-dots {
        bottom: 9px !important;
    }
    .slider-desc h4 label {
        margin: 8px 0;
    }
    ul.amnites-faeture {
        flex-wrap: wrap;
    }
    ul.amnites-faeture li span {
        padding: 7px 9px;
    }
    ul.amnites-faeture li {
        margin-right: 7px;
        margin-bottom: 16px;
    }
    ul.list-gyr ul {
        padding: 0;
    }
    ul.amnites.aminity-wh-img li {
        margin-right: 0;
        margin-bottom: 7px;
        min-width: 50%;
        max-width: 50%;
        float: left;
        padding: 0px 10px;
        font-size: 14px;
    }
    .head1 {
        font-size: 20px;
        margin-bottom: 14px;
    }
    ul.list-gyr {
        margin-bottom: 24px;
    }
    .slider-desc h4 label span {
        font-size: 16px;
    }
    .product-detail-page h6 {
        font-size: 17px;
    }
    ul.list-gyr.list-dot ul {
        padding: 0;
    }
    .price-table {
        margin-top: 30px;
    }
    .you-like-slider h3 {
        font-family: 'Glitten';
        font-size: 22px;
    }
    .you-like-slider h4 {
        font-size: 17px;
    }
    .you-like-slider {
        margin-bottom: 20px;
    }
    h5.amp-loc {
        font-size: 14px;
        position: relative;
        padding-left: 30px;
    }
    h5.amp-loc img {
        position: absolute;
        left: 0;
    }
    ul.amnites li {
        font-size: 14px;
        min-width: 100px;
    }
    ul.amnites-faeture {
        margin-bottom: 17px;
    }
    ul.list-gyr li, p {
        font-size: 16px;
    }
    .sale-btn span {
       
        font-size: 16px;
        padding: 11px 0 6px;
    }
    .pagination ul li a {
        font-size: 15px;
    }
    .list-filter h3 {
        font-size: 20px;
    }
    .pagination ul {
        margin-bottom: 20px;
    }
    .modal-body {
        padding: 0;
    }
    .modal-body img {
        width: 100%;
    }
    .modal-dialog {
        max-width: 100%;
    }
    .filter-main {
        min-width: 320px;
        padding: 14px;
    }
    .filter-list ul li {
        padding: 7px 5px 4px;
        margin-right: 6px;
        margin-bottom: 7px;
    }
    .page-modal h4 {
        font-size: 23px;
        margin-bottom: 18px;
    }
    .page-modal form .from-group {
        width: 100%;
        margin-bottom: 15px;
    }
    .page-modal form .from-group input {
        height: 43px;
    }
    .modal-dialog {
        max-width: 100% !important;
    }   
    .page-modal {
        padding: 16px 16px;
    }
    .product-detail-page .modal-header button {
        display: block !important;
    }
    .modal-header {
        right: 10px;
        top: 17px;
    }
}
.container-fluid {
    padding: 0 !important;
}