.container {
  position: relative;
  width: 100%;
  padding: 100px 0 50px 0;
  overflow-x: hidden;
  max-width: 1920px;
  margin: 0 auto;
}
.wrapper {
  position: relative;
  max-width: 1350px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  grid-gap: 50px;
}
.left-part {
  position: relative;
  display: flex;
  flex-direction: column;
}
.left-part h2 {
  font-family: "Glitten";
  white-space: nowrap;
  position: relative;
  font-size: 400%;
  margin: 0 !important;
  color: #21323C !important;
}
.left-part p {
  font-family: Raleway;
  white-space: nowrap;
  position: relative;
  font-style: italic;
  font-size: 90%;
  font-weight: 600;
  margin: 0 !important;
  color: #21323C !important;
}
.right-part-wrapper {
  position: relative;
  width: 100%;
  border: 2px solid transparent;
  padding: 40px 0 40px 30px;
  transition: 1s ease-in-out;
}
.right-part-wrapper-start-in-initial {
  animation-name: fadeIn; /* Имя ключевых кадров */
  animation-duration: 2s; /* Длительность анимации */
  animation-timing-function: ease-in-out;
  animation-delay: 1s; /* Задержка перед началом анимации */
  animation-fill-mode: forwards; /* Сохранить состояние после анимации */
}

.experiencesBlock{
  padding:50px 0;
}
 .experiencesBlock .image{
  padding-top: 52px;
  position: relative;
}
.experiencesBlock .image img{
  position: relative;
  z-index: 9;
}
.image::before{
  content: '';
  position: absolute;
  background: rgba(0, 149, 219, 0.14);
  top: 0;
  right: 20px;
  width: 273px;
  height: 273px;
  z-index: 1;

}
@keyframes fadeIn {
  0% {
    border-image: linear-gradient(-90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    border-image-slice: 1;
  }
  25% {
    border-image: linear-gradient(-180deg, #dedede, #fff0);
    border-image-slice: 1;
  }
  50% {
    border-image: linear-gradient(-270deg, #dedede, #fff0);
    border-image-slice: 1;
  }
  75% {
    border-image: linear-gradient(0deg, #dedede, #fff0);
    border-image-slice: 1;
  }
  100% {
    border-image: linear-gradient(90deg, #dedede, #fff0);
    border-image-slice: 1;
  }
}
.right-part {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 70px;
  transition: 1s ease-in-out;
  transform: translateX(100%);
  opacity: 0;
}
.right-part-start-in-initial {
  transform: translateX(0%);
  opacity: 1;
}
.right-item-block {
  position: relative;
  display: flex;
  flex-direction: column;
}
.right-item-block img{
  position: relative;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background: #F9E0C2;
}
.right-item-block h4 {
  font-family: Raleway, sans-serif;
  font-size: 110%;
  font-family: Raleway;
  margin-top: 30px;
}
.right-item-block p {
  font-family: Raleway, sans-serif;
  font-size: 85%;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0em;
  text-align: left;
  color: #000000 !important;
  margin-top: 15px;
}
.right-item-block button{
  font-family: Raleway, sans-serif;
  position: relative;
  text-decoration: underline;
  color: #0095DB;
  align-self: start;
  margin-top: auto;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 85%;
  padding: 0 !important;
}

.svg-wrapper {
  position: absolute;
  z-index: 0;
  top: -280px;
  left: 0px;
}
.itemHeading{
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 10px;
}
.itemDescription{
  padding-left: 40px;
  position: relative;
}
.itemDescription::before{
  content: '';
  position: absolute;
  top: -10px;
  bottom: 0;
  left: 15px;
  background-color: #ccc;
  width: 1px;
}
.item:last-child .itemDescription::before{
  content: unset;
}
.luxury-rental-image{
  height: 340px;
}
@media (max-width: 1111px) {
  .container {
    padding: 50px 0;
  }
  .wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
  }
  .right-part-wrapper {
    padding: 0;
  }
  .right-part {
    display: flex;
    flex-direction: column;
    grid-gap: 25px;
  }
  .right-item-block {
    grid-gap: 10px;
  }
  .left-part {
    grid-gap: 10px;
  }
  .left-part h2 {
    text-align: center;
    white-space: nowrap;
    position: relative;
    font-size: 220%;
    padding: 0 !important;
  }
  .left-part p {
    text-align: center;
    white-space: nowrap;
    position: relative;
    font-style: italic;
    opacity: 0.7;
    font-size: 80%;
  }
  .svg-wrapper {
    display: none;
  }
  .right-item-block img{
    position: relative;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background: #F9E0C2;
  }
  .left-part svg {
    margin-left: 3%;
    transform: scale(0.6);
  }
  .right-part-wrapper-start-in-initial {
    animation-fill-mode: none;
    animation-name: none; /* Имя ключевых кадров */
  }
  .right-part {
    transform: translateX(0%);
    opacity: 1;
  }
  .right-item-block h4 {
    margin-top: 10px;
  }
  .right-item-block p {
    margin-top: 0px;
  }
}