.container {
  position: relative;
  cursor: default;
}
.wrapper {
  position: relative;
  display: flex;
  text-decoration: none;
  list-style-type: none;
  color: #000000;
  padding: 0;
  text-transform: capitalize;
}
.wrapper li span {
  margin: auto 5px;
  opacity: 0.6;
  text-transform: capitalize;
}
.wrapper ul {
  text-transform: lowercase; /* Преобразовать все буквы в нижний регистр */
  margin-bottom: 0 !important;
}
.wrapper li {
  text-transform: lowercase; /* Преобразовать все буквы в нижний регистр */
}

.wrapper li::first-letter {
  text-transform: uppercase; /* Сделать первую букву заглавной */
}
.wrapper a {
  opacity: 0.6;
  font-family: Campton-medium!important;
  color: #000000;
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
  transition: 0.3s;
}
.wrapper a:hover {
  opacity: 0.4;
}