.container {
  position: relative;
  width: 100%;
  max-width: calc(1350px + 11%);
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 70px;
}
.swiper-container {
  padding-left: 10%;
  padding-right: 10%;
}
@media (max-width: 767px) {
  .container {
    padding-bottom: 30px;
  }
  .swiper-container {
    padding-left: 5%;
    padding-right: 5%;
  }
}