.container {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.wrapper-content {
  position: relative;
  z-index: 1;
  display: flex;
  grid-gap: 10px;
  align-items: center;
  padding: 10px 20px;
  opacity: 1;
  transition: 0.3s;
  background-color: #0095DB;
}
.wrapper-content-active {
  opacity: 1;
  background-color: #fff;
}
.fon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*opacity: 0.7;*/
  transition: 0.3s;
}
.fon-active {
  /*opacity: 1;*/
  background: rgb(255, 255, 255);
}

.icon {
  max-height: 18px;
  max-width: 18px;
  height: 18px;
  width: 18px;
}
.name {
  font-family: 'Raleway', sans-serif;
  transition: 0.3s;
  font-size: 90%;
  color: #fff !important;  
  margin: 0 !important;
  font-weight: 500;
}
.wrapper-content-active .name{
  color: #0095DB !important;  
}