.container {
  position: fixed;
  bottom: 0;
  background: #0095DB;
  left: 0;
  width: 100%;
  z-index: 99;
  display: flex;
  justify-content: space-between;
}
.right-block {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 5% 15px 5%;
}
.right-block p{
  color: white;
  margin: 0;
}
.right-block span{
  color: white;
  margin: 0;
  margin-left: 5px;
  font-weight: 500;
  font-family: Campton-light;
}
.container button {
  position: relative;
  color: white;
  background: transparent;
  text-align: end;
  width: 50%;
  padding-right: 5%;
}
@media (min-width: 767px) {
  .container {
    display: none;
  }
}