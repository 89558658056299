.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  grid-gap: 5px;
  opacity: 0.3;
  transition: opacity 0.3s;
  cursor: pointer;
}
.container-active {
  opacity: 1;
}
.container p{
  margin: 0;
  font-size: 80%;
}
.container img {
  margin: 0 auto;
  height: 20px;
}