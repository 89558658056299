body {
  margin: 0 auto;
  padding: 0;
  font-family: 'Raleway', sans-serif;
}
img.bnr-img {
  width: 100%;
}
.price-total {
  border-top: 1px solid #ddd;
  margin-top: 13px;
  padding-top: 20px;
}
.aminities-sec {
  display: flex;
}
.bad-name p {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}
.modal-dialog.bathroom-modal {
  max-width: 750px !important;
}
.inner-bath-list {
  padding-bottom: 16px;
  margin-bottom: 12px;
  background: #f0f0f09e;
  padding: 15px 20px 5px;
  position: relative;
}
span.attach-bath {
  background: #bac3cc;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
  padding: 4px 10px 1px;
  border-radius: 100px;
}
.bathroom-data h4 {
  font-size: 21px;
}
.bad-name p label {
  color: #000;
  font-weight: 600;
  min-width: 180px;
}
.inner-bath-list h6 {
  font-size: 20px;
  margin: 5px 0 11px;
}
.aminities-sec span {
  color: #a1a1a1;
  text-decoration: underline;
  cursor: pointer;
  font-size: 24px;
  position: relative;
  top: -3px;
}
.price-total .d-flex.justify-content-between p:first-child {
  font-family: "Campton-medium";
  font-size: 16px;
}
.price-total .d-flex.justify-content-between p {
  font-size: 16px;
  margin-bottom: 1px;
}
.main-banner {
  position: relative;
}
.container {
}

.our-app .inner-play p {
  opacity: 1;
}

.bnr-lg {
  position: absolute;
  top: 20px;
  width: 100%;
}

.slick-slide.slick-active .fadeInUpp {
  position: relative;
  transition: top 1s ease 0s;
  /* only transition top property */
  top: 0;
}

.slick-slide .fadeInUpp {
  position: relative;
  transition: top 1s ease 0s;
  /* only transition top property */
  top: -80px;
}


.overlay-text {
  position: absolute;
  top: 310px;
  /* transform: translateY(-50%); */
  width: 100%;
  max-width: 1400px;
}

.about-main button.slick-arrow {
  display: none !important;
}

.inner-baner-data h2 {
  color: #FFF;
  font-family: 'Glitten';
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 48px 0 30px;
}

.inner-baner-data h4 {
  color: #FFF;
  font-family: 'Glitten';
  font-size: 100px;
}
.modal-dialog.market-modl .modal-content {
  width: auto;
  max-width: 395px;
  margin: 0 auto;
}
.modal-dialog.market-modl .mob-form {
  position: static;
}
.inner-baner-data h3 {
  color: #FFF;
  text-align: left;
  font-family: Raleway;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 3.9px;
  text-transform: uppercase;
  margin: 17px 0 25px;
}

.inner-baner-data button {
  font-size: 16px;
  background: #fff;
  border-radius: 100px;
  padding: 13px 64px;
  text-transform: uppercase;
  font-weight: 500;
  border: 0;
  margin-top: 45px;
}

.tours-main button.ct-btn {
  margin: 40px auto 0;
  display: table;
}

.rooms button.ct-btn {
  margin: 40px auto 0;
  display: table;
}

.carsli img {
  border-radius: 280px 0 280px 0;
}

button.ct-btn {
  background: #0095DB;
  border: 0;
  color: #fff;
  padding: 15px 50px 12px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 22px;
}

button.ct-btn i {
  margin-left: 7px;
  font-size: 12px;
}

.bnr-lg img {
  max-width: 100px;
}

.bnr-form {
  max-width: 395px;
  background: #fff;
  padding: 31px 30px;
  margin-left: auto;
  border-radius: 20px;
}
.dek-overlay, .mob-form {
  position: absolute;
  top: 286px;
  right: 300px;
  max-width: 1350px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.form-group.sign-up i {
  position: absolute;
  right: 93px;
  top: 37px;
  color: #fff;
  font-size: 14px;
}

.form-group.sign-up {
  position: relative;
}

.bnr-form .form-group {
  margin-bottom: 24px;
}

.bnr-form input[type="submit"] {
  background: #0095DB;
  color: #fff;
  font-weight: 600;
  border: 0;
  margin-top: 20px;
  width: 100%;
  height: 47px;
}

.bnr-form h6 {
  color: #000;
  font-family: 'Glitten';
  font-size: 26px;
  text-align: center;
  margin-bottom: 23px;
}

.bnr-form input {
  width: 100%;
  height: 48px;
  border: 0;
  background: #F1F1F166;
  border-bottom: 1px solid #0000001f;
  outline: none;
  padding: 0 10px;
}

.bnr-form label {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  color: #424242;
  margin-bottom: 2px;
}

.right-col-about img {
  width: 100%;
}

.left-col-about h2 {
  color: #21323C;
  font-family: Glitten;
  font-size: 80px;
  line-height: 1.1;
}
.left-col-about h6 {
  color: #0095DB;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 11px 0 24px;
}

.about-main {
  padding: 70px 0;
  background-image: url(../images/lines.png);
  background-repeat: no-repeat;
  background-position: top left;
}

.about-main ul.slick-dots {
  max-width: 666px;
  margin-left: auto;
  float: right;
  left: inherit;
  right: 23px;
  bottom: 18px;
}

.about-main ul.slick-dots li button {
  width: 10px;
  background: #0095DBb0;
  border-radius: 100px;
  padding: 0;
  transition: 0.5s;
  height: 10px;
}
.about-main .slick-dots li button:before {

  opacity: 0 !important;
}

.mina-slider {
  overflow: hidden;
}

.about-main ul.slick-dots li.slick-active button {
  width: 30px;
  transition: 0.5s;
}

.about-main .slick-dots li {
  width: auto;
  height: auto;
}

div#exampleModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  padding: 40px 0;
  z-index: 9;
}
.left-col-about p {
  color: #21323C;
  font-family: Raleway;
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  padding-right: 80px;
}

.left-col-about img {
  margin-bottom: 20px;
}

.rooms.cars .ovr-lay-rom {
  bottom: -54px;
}

.rooms.cars .ovr-lay-rom {
  bottom: -54px;
}

.rooms {
  background: #0095DB30;
  padding: 50px 0;
}

.rooms.cars .rom-inner:hover .ovr-lay-rom {
  bottom: 9px;
}

.rom-inner .ovr-lay-rom {
  position: absolute;
  bottom: -33px;
  left: 18px;
  right: 18px;
  transition: 0.5s;
  z-index: 9;
}

.rom-inner:hover .ovr-lay-rom span {
  width: 100%;
  transition: 0.5s;
}

.rom-inner:hover .ovr-lay-rom {
  bottom: 20px;
  transition: 0.5s;
}

.new-ic {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 19px 0 0;
}

.left-ic-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.left-ic-icons a {
  color: #fff;
  text-decoration: none;
  font-size: 21px;
}

.left-ic-icons a i {
  font-size: 16px;
  margin-left: 4px;
}

.rom-inner .ovr-lay-rom h5 {
  color: #FFF;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.left-ic-icons ul li:last-child {
  margin: 0;
}

.left-ic-icons ul li {
  margin-right: 28px;
  list-style: none;
}

.left-ic-icons ul {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.ovr-lay-rom span {
  width: 120px;
  height: 1px;
  background: #fff;
  display: table;
  margin: 24px 0 11px;
  transition: 0.5s;
}

.ovr-lay-rom h4 {
  color: #FFF;
  font-size: 31px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rom-inner img {
  width: 100%;
  transform: scale(1);
  transition: 0.5s;
}

.rom-inner:hover img {
  transform: scale(1.1);
  transition: 0.5s;
}

.rom-inner {
  position: relative;
  transition: 0.5s;
  overflow: hidden;
  border-radius: 25px;
}

.rooms h2 {
  color: #21323C;
  font-family: Glitten;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 30px;
}

.tours-main {
  background: #faecdc;
  padding: 55px 0;
}

.tours-main h2 {
  color: #21323C;
  font-family: Glitten;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 25px;
}

.rom-inner .ovr-lay-rom li img {
  max-width: 38px;
  max-height: 28px;
  object-fit: contain;
  transform: scale(1) !important;
}

.rom-inner .ovr-lay-rom li {
  width: auto;
  text-align: center;
  margin-right: 22px;
}

.rom-inner .ovr-lay-rom li span {
  width: auto;
  font-size: 13px;
  margin: 4px 0 0;
  background: transparent;
  color: #fff;
  font-weight: 500;
  height: auto;
}

.tour-iner img {
  width: 100%;
  transform: scale(1);
  transition: 0.5s;
}

.tour-overlay {
  position: absolute;
  bottom: -54px;
  left: 24px;
  right: 14px;
  transition: 0.5s;
  z-index: 9;
}

.tour-iner:hover .tour-overlay {
  bottom: 2px;
  transition: 0.5s;
}

.tour-iner::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00000042;
  content: "";
  z-index: 1;
}

.tour-overlay h4 {
  color: #FFF;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 30px;
  line-height: normal;
}

.tour-iner:hover img {
  transform: scale(1.1);
  transition: 0.5s;
}

.tour-iner {
  transition: 0.5s;
  overflow: hidden;
  border-radius: 40px;
}

.tour-overlay p {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.tour-iner {
  position: relative;
}

.eore-dubai h2 {
  color: #21323C;
  font-family: Glitten;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 28px;
}

.eore-dubai {
  padding: 50px 0;
}

.rom-inner::before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.14) 36%, rgba(0, 0, 0, 0.4) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.14) 36%, rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.14) 36%, rgba(0, 0, 0, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#66000000', GradientType=0);
}

.register-property img {
  border-radius: 20px;
}

.inner-dubai img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 0 150px 0 0;
}

.rooms.cars .rom-inner {
  margin-bottom: 33px;
  position: relative;
}

.rooms.cars .rom-inner h5 {
  font-size: 30px;
}

.rooms.cars .rom-inner h4 {
  font-size: 20px;
  font-weight: 500;
}

.rooms.cars .rom-inner::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background: #0000003b;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.inner-dubai h3 {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 10px 0;
}

.inner-dubai p {
  font-size: 17px;
  color: #000;
  font-weight: 500;
}

.our-app .inner-play {
  text-align: center;
  padding-bottom: 140px;
  position: relative;
  top: -40px;
}

.our-app {
  padding-top: 70px;
}

.our-app h3 {
  font-size: 40px;
  font-weight: 400;
  font-family: 'Glitten';
  margin-bottom: 34px;
}

.our-app p {
  font-size: 19px;
  font-weight: 600;
  color: #21323C;
  max-width: 660px;
  margin: 0px auto 69px;
  line-height: 25px;
  opacity: 60%;
  padding: 0 20px;
}

img.frame-one {
  position: absolute;
  bottom: 0;
  left: -140px;
}

img.frame-two {
  position: absolute;
  right: 60px;
  bottom: 0;
}

.our-app {
  background-image: url(../images/line.png);
  background-repeat: no-repeat;
  background-position: center;
  padding: 230px 0 140px;
  margin-top: 80px;
}

.our-app .inner-play h2 {
  color: #21323C;
  font-family: Glitten;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: 43px;
}

/*--footerr--*/
.footerr {
  background: #000;
  padding: 80px 100px 17px;
}

.footerr-left ul {
  padding: 0;
  margin: 0;
}

.footerr-left ul li {
  font-size: 14px;
  list-style: none;
  color: #0000007d;
  margin-bottom: 9px;
}

.footerr-left ul li i {
  min-width: 24px;
  color: #000;
}

.footerr-left {
  padding-right: 60px;
}

.footerr-menu h5 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 17px;
  opacity: 0.5;
}

.footerr-menu ul {
  padding: 0;
  margin: 0;
}

img.ft-img-four {
  position: absolute;
  right: 0;
  bottom: 0;
}

img.ft-img-three {
  position: absolute;
  left: 140px;
  bottom: 0;
}

.register-property {
  position: relative;
  text-align: center;
  padding-bottom: 100px;
  padding-top: 100px;
  margin-bottom: 60px;
}

.register-property h2 {
  font-size: 70px;
  line-height: 77px;
  margin-bottom: 21px;
  color: #fff;
  font-family: Glitten;
}

.register-property p {
  font-size: 21px;
  line-height: 25px;
  max-width: 610px;
  margin: 0 auto 40px;
  color: #fff;
  opacity: 0.6;
}

.footerr-menu ul li {
  list-style: none;
  margin-bottom: 10px;
}

.footerr-menu ul li a {
  font-size: 15px;
  color: #ffffff;
  text-decoration: none;
  opacity: 0.5;
}

.footerr-menu ul i {
  color: #000;
}

.footerr-menu.social ul {
  display: flex;
}

.footerr-menu.social ul li {
  margin-right: 17px;
}

.footerr-menu.social ul li i,
.footerr-menu.social ul li span {
  font-size: 19px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 100px;
}

img.ft-img-one {
  position: absolute;
  top: 0;
  left: 0;
}

img.ft-img-two {
  position: absolute;
  top: 0;
  right: 200px;
}

.fotter-menu {
  border-top: 1px solid #dddddd4d;
  padding-top: 53px;
  padding-bottom: 30px;
}

.fotter-menu p {
  color: #fff;
  font-weight: 400;
  opacity: 0.5;
}

.footerr-left a {
  font-size: 45px;
  color: #fff;
  opacity: 0.5;
  padding-bottom: 28px;
  display: table;
}

.lower-fr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dddddd4d;
  padding-top: 21px;
}

.lower-fr ul {
  padding: 0;
  margin: 0;
}

.lower-fr ul li {
  margin-right: 30px;
}

.lower-fr p {
  color: #fff;
  font-size: 16px;
  opacity: 0.5;
  margin: 0;
}

.lower-fr ul {
  padding: 0;
  margin: 0;
  display: flex;
}

.lower-fr ul li {
  margin-right: 38px;
  list-style: none;
}

.footerr-menu ul li a {
  color: #fff !IMPORTANT;
}

.lower-fr ul li a {
  color: #fff !important;
  text-decoration: none;
  font-size: 16px;
  opacity: 0.5;
}

.register-property a {
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid;
}

.footerr-menu ul li {
  color: #ffffff8f;
}

.register-property a i {
  margin-left: 11px;
  font-size: 16px;
}

.register-property a {
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid;
}

.register-property span a {
  position: absolute;
  left: 0;
  width: 100%;
  height: 27px;
  font-size: 0;
  z-index: 9;
}

.register-property span {
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid;
  position: relative;
  font-weight: 600;
}
.bnr-form input[type=submit] {
  background: #0095DB;
  color: #fff;
  font-weight: 600;
  border: 0;
  margin-top: 20px;
  width: 100%;
  height: 47px;
}

.new-land-page .mob-form, .new-land-page .dek-overlay {
  position: static;
}
.new-land-page .mob-form, .new-land-page .dek-overlay {
  position: absolute;
  top: 286px;
  right: 300px;
  max-width: 1350px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.caption {
  color: #fff;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-shadow: 0px 5px 5px rgba(0, 0, 0, .25);
  margin-bottom: 40px;
  margin-top: 59px;
}
.modal-body .mob-form.pop-modal {
  position: static;
  padding: 21px 13px;
  background: #fff;
}
.modal-body .mob-form.pop-modal .bnr-form {
  margin: 0;
  max-width: 100%;
  padding: 0;
}
.mob-form.pop-modal {
  position: static;
  width: auto;
}
.caption .text-box {
  display: inline-block;
  position: relative;
  width: 100%;
}

.caption .text-box div {
  display: inline-block;
  position: absolute;
  top: -200px;
  transform: rotateX(-90deg);
  opacity: 0;
  text-shadow: 0px 5px 5px rgba(0, 0, 0, .25);
  right: 0;
  animation-timing-function: ease;
  left: 0;
  width: 100%;
}

.inner-baner-data {
  z-index: 9;
  position: relative;
}

.caption .text-box div:nth-child(1) {
  animation: rollDown 8s forwards infinite;
}

.caption .text-box div:nth-child(2) {
  animation: rollDown2 8s forwards infinite;
}

.caption .text-box div:nth-child(3) {
  animation: rollDown3 8s forwards infinite;
}

@keyframes rollDown {
  0% {
    top: -150px;
    transform: rotateX(-90deg);
  }

  11% {
    top: -74px;
    transform: rotateX(0deg);
    opacity: 1;
  }

  22% {
    top: -74px;
    transform: rotateX(0deg);
    opacity: 1;
  }

  33% {
    top: -30px;
    transform: rotateX(30deg);
    opacity: 0;
  }
}

@keyframes rollDown2 {
  33% {
    top: -150px;
    transform: rotateX(-90deg);
  }

  44% {
    top: -74px;
    transform: rotateX(0deg);
    opacity: 1;
  }

  55% {
    top: -74px;
    transform: rotateX(0deg);
    opacity: 1;
  }

  66% {
    top: -30px;
    transform: rotateX(30deg);
    opacity: 0;
  }
}

@keyframes rollDown3 {
  66% {
    top: -150px;
    transform: rotateX(-90deg);
  }

  77% {
    top: -74px;
    transform: rotateX(0deg);
    opacity: 1;
  }

  88% {
    top: -74px;
    transform: rotateX(0deg);
    opacity: 1;
  }

  99% {
    top: -30px;
    transform: rotateX(30deg);
    opacity: 0;
  }
}

@keyframes slideColor {
  0% {
    background-color: #387780;
  }

  33% {
    background-color: #3f88c5;
  }

  66% {
    background-color: #588b8b;
  }

  100% {
    background-color: #387780;
  }
}

/*--responsive-page--*/
@media (max-width:1700px) {
  .inner-baner-data h3 {
    font-size: 25px;
  }

  .inner-baner-data h2 {
    font-size: 83px;
  }
}

@media (max-width:1500px) {
  .overlay-text {
    top: 220px;
  }

  .mob-form {
    top: 220px;
  }

}

@media (max-width:991px) {
  .overlay-text {
    top: 100px;
  }

  .bnr-form {
    right: 10px;
  }

  .mob-form {
    top: 90px;
  }

  .bnr-form {
    right: 30px;
  }

  .about-main {
    padding: 40px 0;
  }

  .left-col-about h2,
  .tours-main h2,
  .eore-dubai h2 {
    font-size: 40px;
  }

  .left-col-about p {
    font-size: 17px;
    padding-right: 20px;
  }

  .inner-baner-data button {
    font-size: 14px;
    padding: 12px 44px;
  }

  .rooms h2 {
    font-size: 40px;
    margin-bottom: 12px;
  }

  .our-app .inner-play h2 {
    font-size: 40px;
  }

  .our-app h3 {
    font-size: 30px;
    margin-bottom: 9px;
  }

  .eore-dubai h2 {
    margin-bottom: 11px;
  }

  .tours-main {
    padding: 40px 0;
  }

  .rooms.cars .rom-inner {
    margin-bottom: 14px;
  }

  .fotter-menu .col-md-3 {
    width: 40%;
  }

  .footer-left {
    padding-right: 20px;
  }

  .footerr {
    padding: 80px 30px 17px;
  }

  .inner-dubai img {
    height: 140px;
  }

  .footer-menu h5 {
    font-size: 17px;
  }

  .rooms {
    padding: 40px 0;
  }

  .inner-dubai h3 {
    font-size: 19px;
  }

  .rooms.cars .col-md-4 {
    width: 50%;
  }

  .our-app {
    padding: 60px 0 40px;
    margin-top: 0;
  }

  img.frame-one {
    bottom: 0;
    left: 100px;
    max-width: 230px;
  }

  .our-app .inner-play {
    padding-bottom: 190px;
    top: 0;
  }

  img.frame-two {
    position: absolute;
    right: 100px;
    bottom: 0;
    max-width: 230px;
    max-height: 210px;
  }

  .rooms.cars .rom-inner .ovr-lay-rom li {
    margin-right: 23px;
  }

  .rooms.cars .rom-inner .ovr-lay-rom li img {
    max-width: 26px;
    max-height: 24px;
  }

  .rooms.cars .rom-inner h4 {
    font-size: 16px;
  }

  .rooms.cars .rom-inner h5 {
    font-size: 22px;
  }

  .inner-dubai p {
    font-size: 16px;
  }

  .tours-main h2 {
    margin-bottom: 11px;
  }

  .rom-inner .ovr-lay-rom h5 {
    font-size: 15px;
  }

  .left-ic-icons a {
    font-size: 14px;
  }

  .tour-overlay h4 {
    font-size: 21px;
  }

  .rom-inner .ovr-lay-rom li img {
    max-width: 21px;
    max-height: 16px;
  }

  .tour-overlay p {
    font-size: 15px;
  }

  .tour-overlay {
    bottom: -74px;
  }

  .rom-inner .ovr-lay-rom li {
    margin-right: 8px;
  }

  .left-col-about h6 {
    font-size: 20px;
    margin: 3px 0 15px;
  }

  .about-main ul.slick-dots {
    max-width: 386px;
  }

  .ovr-lay-rom h4 {
    font-size: 21px;
  }

  .tour-overlay {
    left: 12px;
    right: 12px;
  }

  .left-ic-icons a i {
    font-size: 13px;
    margin-left: 2px;
  }

  img.bnr-img {
    height: 480px;
    object-fit: cover;
  }

  .bnr-form {
    max-width: 345px;
    padding: 27px 14px;
  }

  .inner-baner-data h2 {
    font-size: 44px;
    margin: 18px 0 13px;
  }

  .inner-baner-data h3 {
    font-size: 16px;
    margin: 10px 0 0;
  }

  .inner-baner-data img {
    max-width: 110px;
  }
}

@media (max-width:767px) {
  .inner-baner-data h3 {
    font-size: 14px;
    padding-right: 20px;
  }
  .new-land-page .mob-form, .new-land-page .dek-overlay {
    position: static;
}
.modal-dialog.market-modl button.btn-close {
  top: 10px;
  right: 7px;
}
.modal-dialog.market-modl .bnr-form {
  padding: 24px 18px;
}
  .bnr-lg img {
    max-width: 60px;
  }

  .inner-baner-data h4 {
    font-size: 39px;
  }

  .our-app.show-mob img {
    display: block;
    position: static;
    margin: 0 auto;
    display: table;
  }

  .our-app.show-mob img.frame-two {
    display: none;
  }

  .our-app.show-mob {
    display: block;
    padding-top: 30px;
  }

  .inner-baner-data button {
    font-size: 13px;
    padding: 12px 27px 7px;
    margin-top: 26px;
  }

  .caption {
    margin-bottom: 0px;
    margin-top: 65px;
  }

  .mob-form .bnr-form {
    box-shadow: 0 0 10px 1px #dddddd85;
    right: 0;
    max-width: 100%;
  }

  .mob-form {
    /* width: 100%; */
    display: block;
    /* float: left; */
    position: static;
    padding: 24px 0 11px;
  }

  .about-main ul.slick-dots li button {
    display: flex !IMPORTANT;
  }

  .tour-iner {
    margin-bottom: 14px;
  }

  .overlay-text .bnr-form {
    display: none;
  }

  .eore-dubai {
    padding: 40px 0 23px;
  }

  .inner-baner-data h2 {
    font-size: 29px;
    padding-right: 40px;
  }

  .bnr-form h6 {
    font-size: 22px;
  }

  button.ct-btn {
    padding: 12px 34px 9px;
    margin-top: 14px;
  }

  .carsli img {
    border-radius: 50px 0 50px 0;
  }

  .inner-dubai img {
    border-radius: 0 60px 0 0;
  }

  .inner-baner-data h2 {
    top: 20px;
  }

  .overlay-text {
    top: 101px;
  }

  img.bnr-img {
    height: 360px;
  }

  .lower-fr ul {
    display: none;
  }

  .fotter-menu .col-md-3 {
    width: 100%;
  }

  .footerr {
    padding: 40px 15px 17px;
  }

  .rooms.cars .col-md-4 {
    width: 100%;
  }

  .tour-overlay {
    bottom: -54px;
  }

  .bnr-form {
    max-width: 395px;
    background: #fff;
    padding: 44px 30px;
    margin-left: auto;
    border-radius: 20px;
  }

  .bnr-form  button {
    background: #0095DB;
    color: #fff;
    font-weight: 600;
    border: 0;
    margin-top: 20px;
    border-radius: 100px;
    width: 100%;
    height: 47px;
  }
  .modal-body .mob-form.pop-modal {
    position: static;
    padding: 21px 13px;
    background: #fff;
  }
  .modal-body .mob-form.pop-modal .bnr-form {
    margin: 0;
    max-width: 100%;
    padding: 0;
  }
  .mob-form.pop-modal {
    position: static;
    width: auto;
  }
  .about-main ul.slick-dots {
    max-width: 100%;
    bottom: -30px;
    left: 0;
    z-index: 9;
    right: 0;
    float: none;
    position: static;
  }

  .left-col-about h2,
  .tours-main h2,
  .eore-dubai h2,
  .rooms h2 {
    font-size: 28px;
  }

  .rom-inner .ovr-lay-rom li {
    margin-right: 21px;
  }

  .rooms .rom-inner {
    margin-bottom: 13px;
  }

  .rooms .row.mt-5 {
    margin-top: 0 !important;
  }

  .about-main .row {
    margin: 0;
  }

  .rom-inner .ovr-lay-rom li img {
    max-width: 26px;
    max-height: 22px;
  }
}

.rad {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  user-select: none;
}

.payment-method ul li {
  list-style: none;
}

/* Hide the browser's default radio button */
.rad input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.rad:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.rad input:checked~.checkmark {
  background-color: #0095DB;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.rad input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.rad .checkmark:after {
  top: 7px;
  left: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.rad {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  user-select: none;
}
.payment-method ul li {
  list-style: none;
}
/* Hide the browser's default radio button */
.rad input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.rad:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.rad input:checked ~ .checkmark {
  background-color: #0095DB;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.rad input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.rad .checkmark:after {
 	top: 7px;
	left: 7px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}
.payment-method ul {
  padding: 0;
  margin: 0 0 20px;
}

.cursor-pointer {
  cursor: pointer;
}