.container {
  position: relative;
  display: flex;
  flex-grow: 1;
  margin: 0 auto;
  max-width: 1350px;
  width: 80%;
}
.breadcrumbs {
  position: sticky;
  top: -1px;
  z-index: 10;
  background: white;
  padding: 10px 0;
  max-width: 1350px;
  width: 100%;
}
.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.wrapper h3 {
  text-transform: lowercase; /* Преобразовать все буквы в нижний регистр */
  font-size: 150%;
}
.wrapper h3::first-letter {
  text-transform: uppercase; /* Сделать первую букву заглавной */
}
.block {
  margin-top: 10px;
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: max-content;
  grid-gap: 40px 30px;
}
.loading-block {
  margin: auto 0;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
@media (max-width: 1020px) {

  .block {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 767px) {
  .container {
    width: 90%;
  }
  .block {
    grid-template-columns: 1fr;
  }
  .breadcrumbs {
    padding: 20px 0%;
  }
}