.container {
  position: relative;
  width: 100%;
  display: flex;
  padding: 15px 30px;
  border: unset;
}
.container p{
  margin-bottom: 0 !important;
}
.fon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:#fff;
  backdrop-filter: blur(2px);
  border-radius: 0 10px 10px 10px;

}
.wrapper {
  position: relative;
  padding-right: 10%;
  display: flex;
  width: 100%;
  justify-items: center;
  grid-gap: 5%;
  justify-content: space-between;
}

.back {
  position: absolute;
  width: 100%;
  height: 100%;
}
.wrapper-calendar {
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  grid-gap: 5%;
  padding-right: 10%;
}
.submitBlock {
  margin-left: auto;
  margin-right: 0;
  align-self: center;
}
.wrapper-mobil-data {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: rgba(255, 255, 255, 0.72);
  backdrop-filter: blur(2px);
  z-index: 100;
}
.wrapper-mobil-data-absolute {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) scale(1, 0);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: auto;
  transition: 0.2s;
  transform-origin: top center;
}
.wrapper-mobil-data-absolute-show {
  transform: translateX(-50%) scale(1, 1);
}

@media (max-width: 1300px) {
  .wrapper {
    padding-right: 5%;
  }
}
@media (max-width: 767px) {
  .fon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    backdrop-filter: blur(0px);
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-right: 0%;
  }
  .container {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    border: none;
    padding: 0;
  }
  .submitBlock {
    width: 100%;
  }
}