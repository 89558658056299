.container {
  position: relative;
  width: 100%;
  padding: 0;
}
.wrapper {
  position: relative;
  width: min-content;
  padding: 15px 30px;
  font-size: 110%;
  font-weight: 500;
  transition: 0.3s;
  border: none;
  display: flex;
  justify-content: center;
  grid-gap: 5px;
  align-items: center;
}
.container:hover .wrapper-hover {
  opacity: 0.7;
}
.animated-block {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
  transition: 0.5s;
  transform: translate(-50%, -50%);
  background: black;
}
.container:hover .hover-size {
  transform-origin: center;
  transform: scale(0.9);
}
.container:hover .animated-block{
  width: 100%;
  height: 100%;
}
.hover-size-title {
  transition: 0.2s ease-in-out;
}
.title {
  font-family: Raleway, sans-serif;
  position: relative;
  z-index: 1;
  margin: 0;
}
