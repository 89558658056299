.wrapperFixed {
  position: relative;
  z-index: 1000;
}
.container {
  top: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  width: max-content;
  grid-gap: 5px;
}
.wrapper {
  position: relative;
  display: flex;
  width: max-content;
  align-items: center;
  grid-gap: 10px;
}
.wrapper img {
  position: relative;
  width: auto !important;
  min-height: auto !important;
  height: 18px !important;
}
.title {
  font-family: 'Raleway', sans-serif !important;
  position: relative;
  margin-bottom: 5px;
  opacity: 0.5;
  font-size: 79%;
  color: black !important;
  font-weight: 600;
}
.input {
  position: relative;
  color: black;
  /*background: transparent;*/
  border: none;
  width: max-content;
  min-width: 100%;
  height: 50px;
  font-size: 100%;
  background: transparent;
}
.block {
  position: relative;
}
.block input{
  position: relative;
  border: none;
  background: none;
  outline: none;
  width: min-content;
  font-family: 'Raleway', sans-serif !important;
  font-weight: 500;
  font-size: 90%;
}
.select-container {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  background: white;
  /*height: max-content;*/
  max-height: 200px;
  overflow-y: scroll;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
/* Стилизация полосы прокрутки */
.select-container::-webkit-scrollbar {
  position: relative;
  width: 3px; /* Ширина полосы прокрутки */
}

/* Стилизация бегунка прокрутки */
.select-container::-webkit-scrollbar-thumb {
  position: relative;
  background-color: #888; /* Цвет бегунка */
  border-radius: 2px; /* Закругление углов бегунка */
}

/* Изменение цвета бегунка при наведении */
.select-container::-webkit-scrollbar-thumb:hover {
  position: relative;
  background-color: #555;
}
.selected {
  position: relative;
  background: rgba(227, 182, 126, 0.74);
  color: white;
}
.select-container div {
  position: relative;
  padding: 5px 10px;
  cursor: pointer;
}
.select-container div:hover {
  position: relative;
  background: #0095DB;
  color: white;
}
.select-container div span{
  position: relative;
  font-size: 80%;
}
.select-container strong {
  position: relative;
  margin: 5px 10px;
  font-size: 80%;
  color: black;
  opacity: 0.4;
  text-align: center;
  width: 100%;
}
@media (max-width: 767px) {
  .container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.72);
    backdrop-filter: blur(2px);
    padding: 10px 15px;
    border: 0.5px solid #70707040;
    height: auto;
    z-index: 9999;
    transition: 0.2s ease-in-out;
  }
  .wrapperFixed {
    position: relative;
    z-index: 1000;
    height: 78.6px;
  }
  .container-mobil-active {
    position: fixed;
    top: -176px;
    left: -5%;
    width: 110%;
    height: 100vh;
  }
  .wrapper {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    grid-gap: 10px;
  }
  .block {
    width: 100%;
  }
  .block input{
    width: 100%;
  }
  .select-container {
    position: absolute;
    top: calc(100% + 20px);
    left: 47%;
    transform: translateX(-50%);
    background: transparent;
    /*height: max-content;*/
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 115%;
    font-size: 120%;
  }
  .select-container div {
    padding: 10px 15px;
    padding-left: 30px;
    cursor: pointer;
  }
  .select-container div:hover {
    background: white;
    color: black;
  }
}

