.container {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 50px 0;
  grid-gap: 50px;
  grid-template-areas: "first second third";
  grid-template-rows: 1fr;
}
.first {
  grid-area: first;
}
.second {
  grid-area: second;
}
.third {
  grid-area: third;
}
.center-block {
  position: relative;
  white-space: nowrap;
  text-align: center;
  grid-gap: 0px;
  display: flex;
  flex-direction: column;
}
.center-block h2{
  white-space: break-spaces !important;
  font-size: 300%;
  color: white;
  font-family: "Glitten";
  margin: 0 !important;
  padding: 0 !important;
}
.center-block p{
  margin-top: 30px;
}
.center-block button {
  margin-top: 20px;
  text-decoration: underline;
  color: white;
}
.left-block-images {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 100px;
}
.right-block-images {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
  grid-gap: 70px;
}
@media (max-width: 1111px) {
  .container {
    padding: 30px 0;
    grid-gap: 40px 20px;
    grid-template-areas:
    "first third"
    "second second";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
  .left-block-images {
    grid-gap: 40px;
  }
  .right-block-images {
    grid-gap: 30px;
  }
  .center-block {
    grid-gap: 10px;
  }
  .center-block h2{
    white-space: break-spaces !important;
    font-size: 200%;
    color: white;
    font-family: "Glitten";
  }
  .center-block p{
    white-space: break-spaces !important;
  }
  .center-block p{
    margin-top: 10px;
  }
  .center-block button {
    margin-top: 0px;
  }
}