.container {
  position: relative;
  background: #000;
  color: #90999e;
}
.container p{
  color: #90999e;
}
.container a {
  color: white;
}
.wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 1350px;
  width: 80%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 767px) {
  .wrapper {
    width: 90%;
  }
}
