.container {
  position: relative;
  max-width: 1350px;
  width: 80%;
  margin: 0px auto 0px auto;
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
}

@media (max-width: 767px) {
  .container {
    margin: 0px 0 0px 0;
    width: 100%;
    grid-gap: 40px;
  }
}